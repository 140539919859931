import News from "../components/news/news";
import _map from "lodash/map";
import ReadMore from "../components/buttons/readmore";
import Carousel from "../components/carousel";
import MyPlain from "../components/forms/myPlain";
import LatestBlogs from "../components/latestBlogs";
import Layout from "../components/layout";
import Loading from "../components/loading";
import { useGetVideosQuery } from "../API/videoApi";
import Youtube from "../components/youtube";
import { useGetNewsQuery } from "../API/newsApi";

export default function Home() {
  const videos = useGetVideosQuery(1);
  const news = useGetNewsQuery();
  return (
    <div>
      <Layout>
        <Carousel />
        <div className="container mx-auto mt-8">
          <div className="flex justify-start flex-wrap w-full">
            <div className="w-full md:w-8/12 lg:w-9/12">
              <LatestBlogs />
              <div className="text-right mr-1">
                <ReadMore url={"blog?page=1"} />
              </div>
              <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 m-2">
                {_map(videos?.data?.data, (v) => (
                  <Youtube key={v?.idx} video={v} />
                )).slice(0, 4)}
              </div>
              <hr />
              {news.isLoading ? (
                <Loading />
              ) : (
                news.isSuccess &&
                _map(news.data?.data, (news) => (
                  <News item={news} key={news?.idx} type="news" />
                ))
              )}
            </div>
            <div className="w-full md:w-4/12 lg:w-3/12 mt-2">
              <MyPlain />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
