import React, { useState } from "react";
import ReactStars from "react-stars";
import _get from "lodash/get";
import BuyAndCartButton from "../buyAndCartButton";
import Button from "../buyAndCartButton/button";
import { Config } from "../../config";
import { useAddCartMutation } from "../../API/cartApi";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";

function ProductDetailCard({ product, id }) {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [addToCart, cartResult] = useAddCartMutation();
  let user = null;
  if (typeof window !== "undefined") {
    const usr = JSON.parse(sessionStorage.getItem("user"));
    user = usr;
  }
  const handleAddCart = async () => {
    try {
      if (user?.id === "admin") {
        Notiflix.Notify.failure("Admin can not add to cart product");
        return;
      }
      if (user) {
        Notiflix.Notify.info("Loading...!");
        const obj = {
          product_idx: id,
          user_idx: user?.idx,
        };
        await addToCart(obj);
        setTimeout(() => {
          if (cartResult.data?.message?.includes("already exists")) {
            return Notiflix.Notify.info(cartResult?.data?.message);
          }
          Notiflix.Notify.success(cartResult.data?.message);
        }, 500);
      } else {
        Notiflix.Notify.failure("Please login and then add to cart");
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleBuyNowClick = async () => {
    try {
      if (user?.id === "admin") {
        Notiflix.Notify.failure("Admin can not buy product");
        return;
      }
      const obj = {
        product_idx: id,
        user_idx: user?.idx,
      };
      await addToCart(obj);
      navigate(`/cart?user=${user?.idx}`);
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div>
      {error && JSON.stringify(error)}
      <div className="w-full inline-flex flex-wrap md:flex-nowrap bg-gray-100 rounded-lg overflow-hidden my-4">
        <div>
          <img
            src={`${Config.static}${_get(product, "0.product_image", "null")}`}
            alt={`${Config.static}${_get(product, "0.product_image", "null")}`}
            className="w-full md:w-[850px] lg:w-[600px] xl:w-[550px]"
          />
        </div>
        <div className="m-8 w-full">
          <h1 className="text-2xl md:text-3xl">
            {_get(product, "0.product_title", "Title None")}
          </h1>
          <span className="mt-2 block font-bold">Customer reviews</span>
          <ReactStars
            count={5}
            value={_get(product, "0.review_score", "3")}
            edit={false}
            size={28}
            color2={"#ffd700"}
          />
          <div className="flex justify-between items-center">
            <p className="font-bold">
              <span className="text-5xl">$</span>
              <span className="text-6xl">
                {_get(product, "0.product_price_dollar", "0.00")?.toFixed(2)}
              </span>
            </p>
            <label>
              Quantity
              <input type="number" className="w-16 p-2 border  " value={1} />
            </label>
          </div>
          <div className="text-right ">
            <Button text="Add to Cart" bgColor="buy" onClick={handleAddCart} />
            <Button
              text="Buy it Now"
              bgColor="cart"
              onClick={handleBuyNowClick}
            />
          </div>
        </div>
      </div>
      <div className="p-4 text-xl">
        <div
          className="my-2"
          dangerouslySetInnerHTML={{
            __html: _get(product, "0.product_description", "Description"),
          }}
        />
        <BuyAndCartButton
          handleCartClick={handleAddCart}
          handleBuyClick={handleBuyNowClick}
        />
      </div>
    </div>
  );
}

export default ProductDetailCard;
