import React from "react";
import { Link } from "react-router-dom";

function ReadMore({ url }) {
  return (
    <Link to={`/${url}`}>
      <span className="bg-primary px-2 py-1 text-white rounded-md">
        Read more
      </span>
    </Link>
  );
}

export default ReadMore;
