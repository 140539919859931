import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const slideApi = createApi({
  reducerPath: "slideApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["slide"],
  endpoints: (builder) => ({
    getSlides: builder.query({
      query: () => ({ url: "/main-slide" }),
    }),
  }),
});

export const { useGetSlidesQuery } = slideApi;
