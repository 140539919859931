import React from "react";

function LoginInput({ placeholder, type, value, onChange, ...props }) {
  return (
    <input
      {...props}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      className="border-b block w-full my-4 p-2 focus:outline-none"
    />
  );
}

export default LoginInput;
