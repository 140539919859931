import React from "react";
import _map from "lodash/map";
import Blog from "../blog";
import { useGetBlogsQuery } from "../../API/blogaApi";
import Loading from "../loading";

function LatestBlogs() {
  const { data, isLoading, isError, isSuccess, error } = useGetBlogsQuery(1);
  return (
    <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2">
      {isError && JSON.stringify(error)}
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        isSuccess &&
        _map(data?.data, (blog) => (
          <div className="m-2" key={blog?.idx}>
            <Blog blog={blog} />
          </div>
        )).slice(0, 4)
      )}
    </div>
  );
}

export default LatestBlogs;
