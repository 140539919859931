import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
};
const addCartSlice = createSlice({
  initialState,
  name: "navbar",
  reducers: {
    addCart: (state, { payload }) => {
      state.cart = payload;
    },
    removeCartById: (state, { payload }) => {
      const reCart = state.cart?.filter((s) => s?.idx !== payload);
      state.cart = reCart;
    },
    removeCart: (state) => {
      state.cart = null;
    },
  },
});

export const { addCart, removeCart, removeCartById } = addCartSlice.actions;

export default addCartSlice.reducer;
