import React from "react";
import { Config } from "../../config";

function BlogC({ item }) {
  return (
    <div>
      <img
        src={`${Config.static}${item?.image}`}
        alt={`${Config.static}${item?.image}`}
        className="w-full h-96 object-cover"
      />
      <div
        className="my-8 font-capenhagen text-xl text-gray-600"
        dangerouslySetInnerHTML={{
          __html: item?.content,
        }}
      />
    </div>
  );
}

export default BlogC;
