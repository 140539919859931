import React, { useState, useEffect } from "react";
import Pagination from "../../components/blog/pagination";
import Breadcrumb from "../../components/breadcrumb";
import Layout from "../../components/layout";
import Photo from "../../components/photo";
import { AiOutlineClose } from "react-icons/ai";
import {
  useGetPhotosCategoryQuery,
  useGetPhotosQuery,
  useDeletePhotoMutation,
} from "../../API/photoApi";
import Loading from "../../components/loading";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { MdPostAdd } from "react-icons/md";
import SliderComponent from "../../components/slider";

function Photos() {
  const [user, setUser] = useState();
  const [photoIdx, setPhotoIdx] = useState();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const page = searchParam.get("page");

  const [selectCategory, setSelectCategory] = useState({
    category: "All",
    data: [],
  });
  const photosCategory = useGetPhotosCategoryQuery();
  const photos = useGetPhotosQuery(page ? page : 1);
  const [deletePhoto, deletePhotoResult] = useDeletePhotoMutation();

  const pages = Array?.isArray(photos?.data?.data)
    ? Math.ceil(photos?.data?.data?.length / 10)
    : 1;

  const handleCategoryClick = (category) => {
    const photosByCategory = photos?.data?.data?.filter(
      (item) => item?.category === category
    );
    setSelectCategory({ category, data: photosByCategory });
  };

  const nextPage = () => {
    if (page) {
      if (page == pages) {
        return navigate(`/media/photo?page=${Number(page)}`);
      }
      if (Array.isArray(photos.data?.data)) {
        return navigate(`/media/photo?page=${Number(page) + 1}`);
      }
    }
    return;
  };

  const prevPage = () => {
    if (page) {
      if (page < 2) {
        return navigate(`/media/photo?page=1`);
      }
      navigate(`/media/photo?page=${Number(page) - 1}`);
    }
    return;
  };
  const handlePage = (p) => {
    navigate(`/media/photo?page=${p}`);
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      setUser(JSON.parse(sessionStorage.getItem("user")));
    }
  }, []);

  const handleDeleteClick = async (id) => {
    try {
      await deletePhoto(id);
    } catch (error) {
      console.layout("error", error);
    }
  };
  const handleClickImage = (index) => {
    setPhotoIdx(index);
  };
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        <Breadcrumb
          title="Photo"
          category={selectCategory.category}
          handleCategoryClick={handleCategoryClick}
          content={
            photosCategory.isSuccess
              ? [
                  { category: "All", list_order: 0 },
                  ...photosCategory.data?.data,
                ]
              : []
          }
        />
        {user?.id === "admin" && (
          <div className="flex items-center mr-4">
            <Link to={`/add/photo`}>
              <span className="block bg-gray-50 border p-1 hover:bg-gray-100 active:shadow-inner focus:shadow-inner">
                <MdPostAdd size={18} />
              </span>
            </Link>
          </div>
        )}
        {photos.isLoading && (
          <div className="flex justify-center w-full">
            <Loading />
          </div>
        )}
        {deletePhotoResult.isLoading && (
          <div className="flex justify-center w-full">
            <Loading />
          </div>
        )}

        {photoIdx >= 0 && Array.isArray(photos?.data?.data) && (
          <div className="fixed top-0 left-0 z-50 w-[100vw] h-[100vh] flex justify-center items-center bg-[rgba(0,0,0,0.5)]">
            <div className="p-2 md:p-10 lg:p-14 xl:p-20 ">
              <SliderComponent slides={photos?.data?.data} index={photoIdx} />
            </div>
            <div className="absolute top-4 right-4">
              <button
                onClick={() => setPhotoIdx()}
                className="text-white  w-10 h-10 border rounded-full flex justify-center items-center"
              >
                <span>
                  <AiOutlineClose />
                </span>
              </button>
            </div>
          </div>
        )}

        <div className="grid gap-8 grid-flow-row p-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
          {selectCategory.category === "All"
            ? photos.isSuccess &&
              photos?.data?.data.map((photo, idx) => (
                <Photo
                  key={idx}
                  photo={photo}
                  isAdmin={user?.id === "admin" ? true : false}
                  handleDeleteClick={handleDeleteClick}
                  handleClickImage={() => handleClickImage(idx)}
                />
              ))
            : selectCategory.data?.map((photo, idx) => (
                <Photo
                  key={idx}
                  photo={photo}
                  isAdmin={user?.id === "admin" ? true : false}
                  handleDeleteClick={handleDeleteClick}
                  handleClickImage={() => handleClickImage(idx)}
                />
              ))}
        </div>
        <div className="my-8">
          <Pagination
            type="media"
            pages={pages}
            page={page}
            prevPage={prevPage}
            nextPage={nextPage}
            handlePage={handlePage}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Photos;
