import React from "react";
import { useState } from "react";
import MyPlainBadge from "../badges/myplain";
import _classNames from "classnames";
import Trip from "./trip";
import Tour from "./tour";

function MyPlain() {
  const [tabs, setTabs] = useState(true);
  const hotelTab = () => setTabs(true);
  const tourTab = () => setTabs(false);

  return (
    <div className="bg-primary relative p-2 m-2 md:m-0 rounded-md shadow-ms mt-2">
      <p className="text-white text-2xl">Plan My Trip</p>
      <div className="mt-2">
        <button
          onClick={hotelTab}
          className={_classNames(
            {
              "bg-white": tabs,
              "bg-sky-200": !tabs,
            },
            "px-2 py-1 text-sm rounded-t-md text-primary"
          )}
        >
          Hotels
        </button>
        <button
          onClick={tourTab}
          className={_classNames(
            {
              "bg-white": !tabs,
              "bg-sky-200": tabs,
            },
            "rounded-t-md text-primary px-2 py-1 text-sm "
          )}
        >
          Tours
        </button>
        <hr className="-mt-1 h-1 bg-sky-200" />
      </div>
      <div className="bg-white p-2">{tabs ? <Trip /> : <Tour />}</div>
      <div className="absolute -top-1 right-2">
        <MyPlainBadge />
      </div>
    </div>
  );
}

export default MyPlain;
