import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AiFillCloseSquare } from "react-icons/ai";
import LoginInput from "../inputs/loginInput";
import PrimaryButton from "../buttons/primary";
import { closeNav } from "../navbar/navSlice";
import { useUserSignInMutation } from "../../API/signInApi";
import {
  auth,
  google,
  GoogleAuthProvider,
  signInWithPopup,
} from "../../services/firebase";

function LogIn() {
  const Email = localStorage.getItem("email");
  const Password = localStorage.getItem("password");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState(Email);
  const [error, setError] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState(Password);
  const [signIn, result] = useUserSignInMutation();

  const closeNavbar = () => {
    dispatch(closeNav());
  };
  const text = "Don't have an account?";

  const handleSignIn = async () => {
    try {
      if (email === "" || password === "") {
        setError("Please enter email and password");
        return;
      }
      if (rememberMe) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
      }
      await signIn({ email, password });
    } catch (error) {
      setError("Please provide valid email and password...!");
    }
  };

  const googleAuthLogin = () => {
    signInWithPopup(auth, google)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const obj = {
          email: user?.email,
          password: user?.providerData[0]?.uid,
        };
        await signIn(obj);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage);
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };
  useEffect(() => {
    if (result.data?.message === "User does not exists please sign up") {
      navigate("/signup?user=true");
      dispatch(closeNav());
    }
    if (Array.isArray(result.data?.data)) {
      setTimeout(() => {
        sessionStorage.setItem("user", JSON.stringify(result.data?.data[0]));
        dispatch(closeNav());
        window.location.reload();
      }, 500);
    }
  }, [result.data]);
  return (
    <div
      style={{
        zIndex: "999",
        transform: "translate(-50%,-50%)",
      }}
      className="fixed overflow-hidden top-1/2 left-1/2 right-1/2 w-10/12 m-2 md:w-[50%] h-[70%] bottom-1/2 bg-white p-5 md:p-10 lg:p-16 border"
    >
      <div className="relative">
        <h1 className="text-5xl font-bold">Log In</h1>
        <p className="text-lg my-4">
          {text}
          <Link to="/signup">
            <span
              onClick={() => dispatch(closeNav())}
              className="text-primary ml-2"
            >
              Sign Up
            </span>
          </Link>
        </p>
        <div className="text-center">
          {result.isError && (
            <span className="text-sm text-sky-400">
              {JSON.stringify(result.error)}
            </span>
          )}
          {error && <span className="text-sm text-sky-400">{error}</span>}
          {result.isSuccess && (
            <span className="text-sm text-sky-400">{result.data?.message}</span>
          )}
        </div>
        <LoginInput
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
        <LoginInput
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
        <div className="w-full overflow-hidden flex justify-between">
          <label>
            <input
              type="checkbox"
              value={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <span className="ml-2">Remember me?</span>
          </label>
          <Link to="/forget-password" onClick={() => dispatch(closeNav())}>
            <span className="border-b">Forget Password or Email</span>
          </Link>
        </div>
        <PrimaryButton
          className="border text-lg shadow bg-primary p-2 my-2"
          text={result.isLoading ? "Loading..." : "Log In"}
          onClick={handleSignIn}
        />
        <PrimaryButton
          className="border text-lg shadow bg-[#4385F6] "
          isIcon
          text="Sign In with google"
          onClick={googleAuthLogin}
        />
        <div
          className="absolute top-0 z-50 right-0  md:-top-10  md:-right-10"
          onClick={closeNavbar}
        >
          <AiFillCloseSquare
            size={32}
            color="#62c3f5"
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}

export default LogIn;
