import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import _className from "classnames";
import { BiMinus, BiPlus } from "react-icons/bi";
import { Config } from "../../config";

function CartItem({
  type,
  item,
  handleRemoveCart,
  selectItems,
  select,
  quantityDecrement,
  quantityIncrement,
}) {
  const [count, setCount] = useState(1);

  const increment = () => {
    setCount(count + 1);
    setTimeout(() => {
      quantityIncrement(item, count + 1);
    }, 300);
  };

  const decrement = () => {
    if (count < 2) return setCount(1);
    setCount(count - 1);
    setTimeout(() => {
      quantityDecrement(item, count - 1);
    }, 300);
  };
  return (
    <div className="flex p-4 border-b h-56 overflow-hidden">
      {type === "history" ? (
        <></>
      ) : (
        <div className="block mr-2">
          <label className="inline-flex items-center ">
            <input
              type="checkbox"
              className="h-6 w-6 hover:bg-gray-200"
              checked={
                select?.find((i) => i?.data?.idx === item?.idx) ? true : false
              }
              onClick={() => selectItems(item)}
            />
          </label>
        </div>
      )}
      <div className="flex xs:flex-nowrap">
        <div className="h-[200px] w-[270px] overflow-hidden mr-4">
          <img
            src={`${Config.static}${item?.product_image}`}
            alt={item?.product_title}
            className="h-[200px] w-[200px] object-cover overflow-hidden "
          />
        </div>
        <div className="w-full text-2xl">
          <div
            className={_className(
              {
                "h-40": type !== "history",
                "h-32": type === "history",
              },
              "flex justify-between overflow-hidden"
            )}
          >
            <p>{item?.product_title}</p>
            {type === "history" ? (
              <></>
            ) : (
              <span
                className="block cursor-pointer"
                onClick={() => handleRemoveCart(item?.cart_idx)}
              >
                <GrClose />
              </span>
            )}
          </div>
          <div className="flex justify-between">
            {type === "history" ? (
              <p className="font-bold text-gray-500">
                <span className="text-3xl">$</span>
                <span className="text-4xl">
                  {item?.product_price_dollar?.toFixed(2)}
                </span>
                <span className="text-4xl"> x {item?.product_count}</span>
                <span className="text-xl font-light block">
                  Shipping : ${item?.order_shipping?.toFixed(2)}
                </span>
              </p>
            ) : (
              <p className="font-bold" style={{ alignSelf: "flex-end" }}>
                <span className="text-3xl">$</span>
                <span className="text-4xl">
                  {item?.product_price_dollar?.toFixed(2)}
                </span>
              </p>
            )}
            {type === "history" ? (
              <p className="text-5xl font-bold">
                ${item?.product_count * item?.product_price_dollar?.toFixed(2)}
              </p>
            ) : (
              <div
                className="flex items-center"
                style={{ alignSelf: "flex-end" }}
              >
                <button
                  onClick={decrement}
                  className="border w-8 h-8 flex items-center justify-center"
                >
                  <BiMinus size={20} />
                </button>
                <input
                  type="text"
                  className="w-16 border h-8 text-center"
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                />
                <button
                  onClick={increment}
                  className="border w-8 h-8 flex items-center justify-center"
                >
                  <BiPlus />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
