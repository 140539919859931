import React, { useEffect, useState } from "react";
import _map from "lodash/map";
import Notiflix from "notiflix";
import Pagination from "../../components/blog/pagination";
import Breadcrumb from "../../components/breadcrumb";
import Layout from "../../components/layout";
import Product from "../../components/product";
import {
  useGetShopProductsCategoryQuery,
  useGetShopProductsQuery,
} from "../../API/shopApi";
import Loading from "../../components/loading";
import { useAddCartMutation } from "../../API/cartApi";
import { MdPostAdd } from "react-icons/md";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

function Shop() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const page = searchParams.get("page");

  const [user, setUser] = useState(null);
  const [addToCart, cartResult] = useAddCartMutation();
  const [selectCategory, setSelectCategory] = useState({
    category: 0,
    data: [],
  });
  const shopProducts = useGetShopProductsQuery(page ? page : 1);
  const shopProductsCategory = useGetShopProductsCategoryQuery();

  const pages = Array?.isArray(shopProducts.data?.data)
    ? Math.ceil(shopProducts.data?.data?.length / 10)
    : 1;
  const nextPage = () => {
    if (page) {
      if (page == pages) {
        return navigate(`/shop?page=${Number(page)}`);
      }
      if (Array.isArray(shopProducts?.data?.data)) {
        return navigate(`/shop?page=${Number(page) + 1}`);
      }
    }
    return;
  };

  const prevPage = () => {
    if (page) {
      if (page < 2) {
        return navigate(`/shop?page=1`);
      }
      navigate(`/blog?page=${Number(page) - 1}`);
    }
    return;
  };
  const handlePage = (p) => {
    navigate(`/shop?page=${p}`);
  };

  const handleCategoryClick = (category) => {
    const photosByCategory = shopProducts?.data?.data?.filter(
      (item) => item?.category_idx === category
    );
    setSelectCategory({ category, data: photosByCategory });
  };

  useEffect(() => {
    if (page) {
      return;
    } else {
      navigate("/shop?page=1");
    }
  }, [page]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const usr = JSON.parse(sessionStorage.getItem("user"));
      setUser(usr);
    }
  }, []);

  const handleAddCart = async (values) => {
    try {
      if (user?.id === "admin") {
        Notiflix.Notify.failure("Admin can not add to card product");
        return;
      }
      if (user) {
        Notiflix.Notify.info("Loading...!");
        const obj = {
          product_idx: values?.idx,
          user_idx: user?.idx,
        };
        await addToCart(obj);
        setTimeout(() => {
          if (cartResult.data?.message?.includes("already exists")) {
            return Notiflix.Notify.info(cartResult?.data?.message);
          }
          Notiflix.Notify.success(cartResult.data?.message);
        }, 500);
      } else {
        Notiflix.Notify.failure("Please login and then add to cart");
      }
    } catch (error) {
      console.log("ERROR IN ADD TO CART", error);
    }
  };

  return (
    <Layout>
      <div className="container mx-auto mt-32 p-2">
        {user?.id === "admin" && (
          <Link to="/add/product">
            <MdPostAdd />
          </Link>
        )}
        <Breadcrumb
          title="Shop"
          category={selectCategory.category}
          handleCategoryClick={handleCategoryClick}
          content={
            shopProductsCategory.isSuccess
              ? [
                  { idx: 0, title: "All", list_order: 0 },
                  ...shopProductsCategory.data?.data,
                ]
              : []
          }
        />
        {shopProducts.isLoading ? (
          <div className="w-full flex justify-center">
            <Loading />
          </div>
        ) : (
          <div className="grid grid-flow-row grid-cols-1 gap-4 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6">
            {selectCategory.category === 0
              ? _map(shopProducts?.data?.data, (s) => (
                  <Product
                    key={s?.idx}
                    product={s}
                    handleAddCart={handleAddCart}
                  />
                ))
              : _map(selectCategory.data, (s) => (
                  <Product
                    key={s?.idx}
                    product={s}
                    handleAddCart={handleAddCart}
                  />
                ))}
          </div>
        )}
        <div className="my-8">
          <Pagination
            type="shop"
            pages={pages}
            page={page}
            prevPage={prevPage}
            nextPage={nextPage}
            handlePage={handlePage}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Shop;
