import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../../components/inputs/textInput";
import Button from "../../../components/buttons/button";
import { AiOutlineFileImage } from "react-icons/ai";
import { Config } from "../../../config";
import Loading from "../../../components/loading";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetShopProductQuery,
  useGetShopProductsCategoryQuery,
} from "../../../API/shopApi";
import RichTextEditor from "react-rte";

const initialValues = {
  product_title: "",
  product_price_dollar: "",
  product_actual_weight_kg: "",
  product_width_cm: "",
  product_length_cm: "",
  product_height_cm: "",
  inventory_count: "",
  category_idx: 1,
};

const schema = Yup.object().shape({
  product_title: Yup.string().required(),
  product_price_dollar: Yup.number().required(),
  product_actual_weight_kg: Yup.number().required(),
  product_width_cm: Yup.number().required(),
  product_length_cm: Yup.number().required(),
  product_height_cm: Yup.number().required(),
  inventory_count: Yup.number().required(),
  category_idx: Yup.number().required().default(1),
});

function UpdateProduct() {
  const { id } = useParams();
  const navigate = useNavigate();
  let userAdmin;
  if (typeof window !== "undefined") {
    userAdmin = JSON.parse(sessionStorage.getItem("user"));
  }
  const shopProductsCategory = useGetShopProductsCategoryQuery();
  const shopProduct = useGetShopProductQuery(id);
  const [content, setContent] = useState(RichTextEditor.createEmptyValue());
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const handleAddProduct = async (values, { setSubmitting }) => {
    try {
      setLoading(true);

      const formdata = new FormData();
      formdata.append("product_image", file);
      formdata.append("product_title", values?.product_title);
      formdata.append(
        "product_description",
        content.toString("html")?.replaceAll(`"`, `'`)
      );
      formdata.append("product_price_dollar", values?.product_price_dollar);
      formdata.append(
        "product_actual_weight_kg",
        values?.product_actual_weight_kg
      );
      formdata.append("product_width_cm", values?.product_width_cm);
      formdata.append("product_length_cm", values?.product_length_cm);
      formdata.append("product_height_cm", values?.product_height_cm);
      formdata.append("inventory_count", values?.inventory_count);
      formdata.append("category_idx", values?.category_idx);
      const product = await fetch(`${Config.server}/shops/${id}`, {
        method: "PUT",
        body: formdata,
      });
      const result = await product.json();
      setLoading(false);
      setMessage(result?.message);
      window.location.href = "/shop?page=1";
    } catch (error) {
      setMessage(JSON.stringify(error));
    }
  };
  useEffect(() => {
    if (userAdmin?.id !== "admin") {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (Array.isArray(shopProduct.data?.data)) {
      const data = shopProduct.data?.data[0];
      initialValues.product_title = data?.product_title;
      initialValues.product_price_dollar = data?.product_price_dollar;
      initialValues.product_width_cm = data?.product_width_cm;
      initialValues.product_length_cm = data?.product_length_cm;
      initialValues.product_actual_weight_kg = data?.product_actual_weight_kg;
      initialValues.category_idx = data?.category_idx;
      initialValues.product_height_cm = data?.product_height_cm;
      initialValues.inventory_count = data?.inventory_count;
      setFile(data?.product_image);
      setImage(`${Config.host}${data?.product_image}`);
      RichTextEditor.createValueFromString(data?.product_description, "html");
    }
  }, [shopProduct.data?.data[0]]);

  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "header-one") {
      return "rte-heading-one";
    }
    if (type === "header-two") {
      return "rte-heading-two";
    }
    if (type === "header-three") {
      return "rte-heading-three";
    }
  };
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {loading && <Loading />}
        {message}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleAddProduct}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                placeholder="Title"
                type="text"
                onChange={handleChange}
                label="Title"
                name="product_title"
                value={values.product_title}
              />
              {errors.product_title &&
                touched.product_title &&
                errors.product_title}

              <label className="text-sm">Description</label>
              <div className="h-full">
                <RichTextEditor
                  value={content}
                  onChange={(value) => setContent(value)}
                  className="min-h-[300px] max-h-full"
                  blockStyleFn={myBlockStyleFn}
                />
              </div>

              <TextInput
                placeholder="0.00"
                type="number"
                onChange={handleChange}
                label="Price in doller"
                name="product_price_dollar"
                value={values.product_price_dollar}
              />
              {errors.product_price_dollar &&
                touched.product_price_dollar &&
                errors.product_price_dollar}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Weight in KG"
                name="product_actual_weight_kg"
                value={values.product_actual_weight_kg}
              />
              {errors.product_actual_weight_kg &&
                touched.product_actual_weight_kg &&
                errors.product_actual_weight_kg}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Width CM"
                name="product_width_cm"
                value={values.product_width_cm}
              />
              {errors.product_width_cm &&
                touched.product_width_cm &&
                errors.product_width_cm}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Length CM"
                name="product_length_cm"
                value={values.product_length_cm}
              />
              {errors.product_length_cm &&
                touched.product_length_cm &&
                errors.product_length_cm}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Height CM"
                name="product_height_cm"
                value={values.product_height_cm}
              />
              {errors.product_height_cm &&
                touched.product_height_cm &&
                errors.product_height_cm}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Inventory Count"
                name="inventory_count"
                value={values.inventory_count}
              />
              {errors.inventory_count &&
                touched.inventory_count &&
                errors.inventory_count}

              <TextInput
                placeholder="10"
                type="select"
                isSelect
                onChange={handleChange}
                label="Category"
                name="category_idx"
                value={values.category_idx}
                data={shopProductsCategory?.data?.data}
              />
              {errors.category_idx &&
                touched.category_idx &&
                errors.category_idx}
              <div className="flex justify-center">
                {image && (
                  <img
                    src={file ? `${Config.static}${file}` : image}
                    alt={file ? `${Config.static}${file}` : image}
                    className="h-56 "
                  />
                )}
              </div>
              <div className="border rounded my-2 shadow-sm p-2">
                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpg, image/jpeg"
                  hidden
                  name="image"
                  onChange={(e) => {
                    setImage(URL.createObjectURL(e.target.files[0]));
                    setFile(e.target.files[0]);
                  }}
                />
                <label
                  htmlFor="file"
                  className="flex justify-center items-center"
                >
                  <AiOutlineFileImage />{" "}
                  <span className="-mt-1 mx-4">Upload</span>
                </label>
              </div>
              {errors.image && touched.image && errors.image}
              <Button text="Update Product" />
            </form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}

export default UpdateProduct;
