import React from "react";
import { HiLocationMarker } from "react-icons/hi";
import { MdArrowDropDown } from "react-icons/md";
import _classNames from "classnames";

function Select({ label, isIcon = false, data = null, handleChange }) {
  return (
    <div className="relative">
      <label className="flex text-sm">
        <MdArrowDropDown color="#62c3f5" size={20} /> {label}
      </label>
      <select
        onChange={handleChange}
        className={_classNames(
          {
            "px-6": isIcon,
          },
          "block border w-full p-2 rounded-md shadow-md"
        )}
      >
        {label === "City"
          ? data &&
            data?.map(({ city, idx }) => (
              <option value={city} key={idx}>
                {city}
              </option>
            ))
          : label === "Where"
          ? data?.map(({ where, idx, url }) => (
              <option value={url} key={idx}>
                {where}
              </option>
            ))
          : data?.map(({ name, id }) => (
              <option value={name} key={id}>
                {name}
              </option>
            ))}
      </select>
      {isIcon && (
        <div className="absolute top-7 left-1">
          <HiLocationMarker color="#d6d6d6" size={20} />
        </div>
      )}
    </div>
  );
}

export default Select;
