import React, { useEffect, useState } from "react";
import CartItems from "../../../components/cart/cartItems";
import OrderSummery from "../../../components/cart/ordersummery";
import Layout from "../../../components/layout";
import {
  useGetCartQuery,
  useRemoveCartItemMutation,
} from "../../../API/cartApi";
import { BsFillCartDashFill } from "react-icons/bs";
import Notiflix from "notiflix";
import _sumBy from "lodash/sumBy";
import _sum from "lodash/sum";
import _find from "lodash/find";
import Loading from "../../../components/loading";
import { useDispatch } from "react-redux";
import { addCart } from "../../../components/product/addToCartSlice";
import {
  useCountryCostMutation,
  useCountryListQuery,
} from "../../../API/countryApi";
import { useNavigate, useSearchParams } from "react-router-dom";

function Cart() {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const user = searchParam.get("user");
  const [select, setSelect] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [productWeight, setProductWeight] = useState();
  const [countryCostWeight, setCountryCostWeight] = useState([]);
  const [shippingCost, setShippingCost] = useState();
  const countryList = useCountryListQuery();
  const [countryCost, countryCostState] = useCountryCostMutation();
  const dispatch = useDispatch();
  const cart = useGetCartQuery(Number(user));
  const [subTotal, setSubTotal] = useState(0);
  const [removeItemCart] = useRemoveCartItemMutation();
  const handleRemoveCart = async (id) => {
    try {
      Notiflix.Notify.info("Loading...!");
      await removeItemCart(id);
      Notiflix.Notify.success("Remove Successfully...!");
    } catch (error) {
      Notiflix.Report.failure("Error remove item from cart");
    }
  };
  const calculateWeight = () => {
    let mulWeight = [];
    select?.map((item) => {
      const totalWeight = item?.quantity * item?.data?.product_actual_weight_kg;
      mulWeight.push(totalWeight);
      return [];
    });
    const weightSum = _sum(mulWeight)?.toFixed(2);
    setProductWeight(weightSum);
    if (Array.isArray(countryCostState.data?.data)) {
      let findCost = _find(countryCostWeight, function (i) {
        return i.actual_or_volume_weight_kg >= parseFloat(weightSum);
      });
      setShippingCost(findCost);
    }
  };

  const calculateShippingCost = async (e) => {
    const { value } = e.target;
    const country = value?.split("-");
    const countryCostResponse = await countryCost(
      country[0] ? country[0] : "AU"
    );
    if (Array.isArray(countryCostResponse.data?.data)) {
      setCountryCostWeight(countryCostResponse.data?.data);
      calculateWeight();
    }
    calculateWeight();
  };

  const selectAll = () => {
    const newVal = [];
    if (select?.length > 0) {
      setSubTotal(_sumBy(newVal, "total")?.toFixed(2));
      setSelect([]);
      calculateWeight();
      return;
    }

    cart?.data?.data?.map((item) => {
      newVal.push({
        data: item,
        quantity: 1,
        total: item?.product_price_dollar * 1,
        shippingCost,
      });
      return [];
    });
    setSelect(newVal);
    setSubTotal(_sumBy(newVal, "total")?.toFixed(2));
  };
  useEffect(() => {
    calculateWeight();
  }, [select, productWeight, shippingCost, countryCostWeight]);

  const defaultCountryCost = async () => {
    try {
      const response = await countryCost("AU");
      setCountryCostWeight(response.data?.data);
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    defaultCountryCost();
  }, []);

  const selectItems = (value) => {
    const exists = select?.find((i) => i?.data?.idx === value?.idx);
    if (exists) {
      const rmItem = select?.filter((i) => i?.data?.idx !== value?.idx);
      setSubTotal(_sumBy(rmItem, "total")?.toFixed(2));
      setSelect(rmItem);
      calculateWeight();
      return;
    }
    const newVal = [
      ...select,
      {
        data: value,
        quantity: 1,
        total: value?.product_price_dollar * 1,
      },
    ];
    setSelect(newVal);
    setSubTotal(_sumBy(newVal, "total")?.toFixed(2));
    calculateWeight();
  };

  const quantityIncrement = (item, quantity) => {
    const itemIndex = select?.findIndex((i) => i?.data?.idx === item?.idx);
    let updateSelect = select;
    updateSelect[itemIndex] = {
      data: item,
      quantity,
      total: item?.product_price_dollar * quantity,
    };
    setSelect(updateSelect);
    setSubTotal(_sumBy(updateSelect, "total").toFixed(2));
    calculateWeight();
  };

  const quantityDecrement = (item, quantity) => {
    const itemIndex = select?.findIndex((i) => i?.data?.idx === item?.idx);
    let updateSelect = select;
    updateSelect[itemIndex] = {
      data: item,
      quantity,
      total: item?.product_price_dollar * quantity,
    };
    setSelect(updateSelect);
    setSubTotal(_sumBy(updateSelect, "total")?.toFixed(2));
    calculateWeight();
  };
  const handleBuy = () => {
    if (shippingCost) {
      const data = {
        products: select,
        shippingCost,
      };
      dispatch(addCart(data));
      setTimeout(() => {
        navigate("/shipping");
      }, 200);
      return;
    }
    setErrorMessage("Please select country to calculate cost...");
  };
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        <h1 className="p-2 font-semibold">
          <span className="text-2xl md:text-4xl">S</span>
          <span className="text-lg md:text-2xl">
            hopping Cart (
            {Array.isArray(cart.data?.data?.length)
              ? cart.data?.data?.length
              : 0}
            )
          </span>
        </h1>
        {cart.isLoading ? (
          <Loading />
        ) : Array.isArray(cart?.data?.data) ? (
          <div className="bg-gray-100 flex flex-wrap">
            <div className="w-full lg:w-9/12">
              <CartItems
                data={cart.data?.data}
                selectAll={selectAll}
                selectItems={selectItems}
                handleRemoveCart={handleRemoveCart}
                select={select}
                quantityIncrement={quantityIncrement}
                quantityDecrement={quantityDecrement}
              />
            </div>
            <div className="bg-gray-100 w-full lg:w-3/12">
              <p>{errorMessage}</p>
              <OrderSummery
                total={subTotal}
                item={select?.length}
                handleBuy={handleBuy}
                countryList={countryList.data?.data}
                productWeight={productWeight}
                shippingCost={countryCostState.isLoading ? 0.0 : shippingCost}
                calculateShippingCost={calculateShippingCost}
                loading={countryCostState.isLoading}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center my-24">
            <div>
              <BsFillCartDashFill size={100} className="text-sky-400" />
              <span className="block text-2xl text-sky-400">Empty cart</span>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Cart;
