import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

function Video({ video, isAdmin, handleDeleteClick }) {
  return (
    <div className="p-4 my-4 md:p-0 rounded-lg overflow-hidden">
      <a href={video?.url} target="_blank">
        <div className="h-56 w-full relative">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${video?.url?.split("?v=")[1]}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded youtube"
          />
          <div className="absolute top-0 left-0 h-56 w-[100vw] bg-transparent"></div>
        </div>
        <p className="p-2 text-xl">{video?.title}</p>{" "}
      </a>
      <div className="flex justify-between">
        <span className="text-gray-400 text-xs px-2">
          {moment(video?.reg_date).fromNow()}
        </span>
        {isAdmin && (
          <div className="flex">
            <button
              onClick={() => handleDeleteClick(video?.idx)}
              className="block bg-red-400 text-white border p-1 hover:bg-red-500 active:shadow-inner focus:shadow-inner"
            >
              <RiDeleteBinLine size={18} />
            </button>
            <Link to={`/update/video/${video?.idx}`}>
              <span className="block bg-gray-50 border p-1 hover:bg-gray-100 active:shadow-inner focus:shadow-inner">
                <FiEdit size={18} />
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Video;
