import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const videoApi = createApi({
  reducerPath: "videoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["videos"],
  endpoints: (builder) => ({
    getVideos: builder.query({
      query: (page = 1) => ({ url: `/videos?page=${page}` }),
      providesTags: ["videos"],
    }),
    getVideo: builder.query({
      query: (id) => ({ url: `/videos/${id}` }),
      providesTags: ["videos"],
    }),
    getVideosCategory: builder.query({
      query: () => ({ url: "/videos-category" }),
      providesTags: ["videos"],
    }),
    addVideo: builder.mutation({
      query: (body) => ({ url: `/videos`, body, method: "POST" }),
      invalidatesTags: ["videos"],
    }),
    deleteVideo: builder.mutation({
      query: (id) => ({ url: `/videos/${id}`, method: "DELETE" }),
      invalidatesTags: ["videos"],
    }),
  }),
});

export const {
  useGetVideosCategoryQuery,
  useGetVideosQuery,
  useAddVideoMutation,
  useGetVideoQuery,
  useDeleteVideoMutation,
} = videoApi;
