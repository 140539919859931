import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Layout from "../../components/layout";
import Loading from "../../components/loading";
import { RiDeleteBinLine } from "react-icons/ri";
import ProductDetailCard from "../../components/product/productDetail";
import {
  useDeleteProductMutation,
  useGetShopProductQuery,
} from "../../API/shopApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdPostAdd } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

function ProductDetails() {
  const [userAdmin, setUserAdmin] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleteProduct] = useDeleteProductMutation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setUserAdmin(JSON.parse(sessionStorage.getItem("user")));
    }
  }, []);
  const { data, isLoading } = useGetShopProductQuery(id);

  const handleDeleteProduct = async () => {
    try {
      await deleteProduct(id);
      navigate("/shop");
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  return (
    <Layout>
      <div className="container mx-auto p-4 mt-24">
        {userAdmin?.id === "admin" && (
          <div className="flex justify-end">
            <Link to={`/add/product`}>
              <span className="block bg-gray-50 border p-1 hover:bg-gray-100 active:shadow-inner focus:shadow-inner">
                <MdPostAdd size={18} />
              </span>
            </Link>
            <Link to={`/update/product/${id}`}>
              <span className="block bg-gray-50 border p-1 hover:bg-gray-100 active:shadow-inner focus:shadow-inner">
                <FiEdit size={18} />
              </span>
            </Link>
            <button
              onClick={handleDeleteProduct}
              className="block bg-red-400 text-white border p-1 hover:bg-red-500 active:shadow-inner focus:shadow-inner"
            >
              <RiDeleteBinLine size={18} />
            </button>
          </div>
        )}
        <Breadcrumb title="Shop" content={[]} />
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <ProductDetailCard product={data?.data} id={id} />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default ProductDetails;
