import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="border-t p-4 flex justify-evenly flex-wrap ">
      <div>
        <h1 className="text-2xl font-bold">Korea-Travel.net</h1>
        <h1 className="text-2xl font-bold">About-Korea.net</h1>
        <p className="text-gray-400 text-xs">
          Copyright &copy; 1996-2022 Korea-Travel.net All Right Reserved
        </p>
      </div>
      <div className="flex justify-evenly flex-wrap ">
        <Link to="#">
          <span className="text-xl px-3">About Us</span>
        </Link>
        <Link to="#">
          <span className="text-xl px-3">Employement </span>
        </Link>
        <Link to="#">
          <span className="text-xl px-3">Contact Us</span>
        </Link>
        <Link to="#">
          <span className="text-xl px-3">Advertising</span>
        </Link>
        <Link to="#">
          <span className="text-xl px-3">Privacy & Terms</span>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
