import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const shopApi = createApi({
  reducerPath: "shopApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["shops"],
  endpoints: (builder) => ({
    getShopProducts: builder.query({
      query: (page = 1) => ({ url: `/shops?page=${page}` }),
      providesTags: ["shops"],
    }),
    getShopProduct: builder.query({
      query: (id) => ({ url: `/shops/${id}` }),
      providesTags: ["shops"],
    }),
    getShopProductsCategory: builder.query({
      query: () => ({ url: "/shops-category" }),
      providesTags: ["shops"],
    }),
    deleteProduct: builder.mutation({
      query: (id) => {
        return {
          url: `/shops/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["shops"],
    }),
  }),
});

export const {
  useGetShopProductsQuery,
  useGetShopProductsCategoryQuery,
  useGetShopProductQuery,
  useDeleteProductMutation,
} = shopApi;
