import React, { useEffect } from "react";
import _sumBy from "lodash/sumBy";
import _find from "lodash/find";
import _isNaN from "lodash/isNaN";

function OrderSummery({
  total,
  item,
  handleBuy,
  countryList,
  calculateShippingCost,
  productWeight,
  shippingCost,
  loading,
}) {
  const t = Number(total) + Number(shippingCost?.cost_dollar);
  useEffect(() => {}, [loading, productWeight, shippingCost]);
  return (
    <div className="bg-white m-2 p-4 rounded-lg">
      <h1 className="text-3xl border-b">Order Summery</h1>

      <div className="flex justify-between text-2xl mt-4">
        <p>Subtotal</p>
        <p>${_isNaN(total) ? 0.0 : total}</p>
      </div>
      <select
        onChange={calculateShippingCost}
        className="w-full border p-3 mt-4 rounded-md shadow-sm text-gray-400"
      >
        {countryList?.map(({ area_id, country_name }, idx) => (
          <option key={idx} value={`${area_id}-${country_name}`}>
            {country_name}
          </option>
        ))}
        <option>Country*</option>
      </select>
      <div className="flex justify-between text-2xl mt-4">
        <p>Weight</p>
        <p className="border-b border-dashed">{productWeight} KG</p>
      </div>
      <div className="flex justify-between text-2xl mt-4">
        <p>Shipping</p>
        {loading ? (
          <p>Loading</p>
        ) : (
          <p className="border-b border-dashed">
            ${shippingCost ? shippingCost?.cost_dollar?.toFixed(2) : 0.0}
          </p>
        )}
      </div>
      <div className="flex justify-between text-3xl font-bold mt-4">
        <p>Total</p>
        {loading ? <p>Loading</p> : <p>${_isNaN(t) ? 0.0 : t?.toFixed(2)}</p>}
      </div>
      <button
        onClick={handleBuy}
        disabled={item === 0 ? true : false}
        className={`${
          item === 0 ? "cursor-not-allowed" : "cursor-pointer"
        } w-full p-2 block text-center bg-primary text-white rounded-md shadow-md text-2xl mt-4`}
      >
        Buy ({item})
      </button>
    </div>
  );
}

export default OrderSummery;
