import React, { useState, useRef, useEffect } from "react";
import Layout from "../../../../components/layout";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri";
import _sumBy from "lodash/sumBy";
import _isNaN from "lodash/isNaN";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  useAddOrderDetailMutation,
  useAddOrderMutation,
} from "../../../../API/orderApi";
import { Link } from "react-router-dom";

const initialValues = {
  order_id: "",
  order_state: "",
  order_name: "",
  order_tel: "",
  order_address: "",
  order_zip: "",
  order_email: "",
  order_shipping: "",
};

const schema = Yup.object().shape({
  order_name: Yup.string().required("Name is required..."),
  order_address: Yup.string().required("Address is required..."),
  order_zip: Yup.string().required("Zip Code is required..."),
  order_email: Yup.string().email().required("Email is required..."),
});

function Shipping() {
  const [phoneCode, setPhoneCode] = useState();
  const state = useSelector((state) => state.cart);
  const [user, setUser] = useState();
  const [addOrder] = useAddOrderMutation();
  const [addOrderDetail] = useAddOrderDetailMutation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);

  const sum =
    _sumBy(state.cart?.products, "total") +
    parseFloat(state?.cart?.shippingCost?.cost_dollar);

  const paypal = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=ASoSyp-xcQ-7D4Y9aGDihdn0IA3r94GmnYcSsvwGtJaOybSgA6JAWqg8t6xW-wFl66tt3qY1yqjCGyk6&components=buttons";
    document.body.append(script);
  }, []);

  useEffect(() => {
    setUser(JSON.parse(sessionStorage.getItem("user")));
  }, []);

  const renderButtons = async (values) => {
    if (typeof window !== "undefined") {
      if ("paypal" in window) {
        window.paypal
          .Buttons({
            createOrder: function (data, actions) {
              return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [
                  {
                    amount: {
                      currency_code: "USD",
                      value: sum?.toFixed(2),
                    },
                  },
                ],
              });
            },
            onApprove: function (data, actions) {
              // This function captures the funds from the transaction.
              return actions.order.capture().then(async function (details) {
                const obj = {
                  order_id: details?.id,
                  order_state: "Shipping",
                  order_name: values.order_name,
                  order_tel: phoneCode,
                  order_address: values.order_address,
                  order_zip: values.order_zip,
                  order_email: values.order_email,
                  order_shipping: state?.cart?.shippingCost?.cost_dollar,
                  user_idx: user?.idx,
                };
                const response = await addOrder(obj);

                if (Array.isArray(response.data?.data))
                  state?.cart?.products?.map(async (item) => {
                    const objDetails = {
                      order_idx: response.data?.data[0]?.idx,
                      product_idx: item?.data?.idx,
                      category_title: "1",
                      product_title: item?.data?.product_title,
                      product_description: item?.data?.product_description,
                      product_price: item?.data?.product_price_dollar,
                      product_count: item?.quantity,
                    };
                    await addOrderDetail(objDetails);
                  });
                setIsSuccess(true);
                // This function shows a transaction success message to your buyer.
              });
            },
            onError: function (err) {
              setError(err);
            },
            style: {
              layout: "vertical",
              color: "blue",
              shape: "rect",
              label: "paypal",
            },
          })
          .render(paypal.current);
      }
    }
  };

  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {isSuccess && (
          <div className="flex justify-center items-center h-[85vh]">
            <div>
              <h1 className="text-4xl text-green-800">Order Successful...!</h1>
              <Link to="/">Back</Link>
            </div>
          </div>
        )}
        {error && (
          <div className="flex justify-center text-center items-center h-[85vh]">
            <div>
              <h1 className="text-4xl text-rose-800 ">Oppssss....</h1>
              <h1 className="text-4xl text-rose-800">Something error...! </h1>
              <p>{JSON.stringify(error)}</p>
              <Link to="/">
                <span className="block text-center underline text-xl mt-2 text-sky-600">
                  Back
                </span>
              </Link>
            </div>
          </div>
        )}
        {!isSuccess && !error && (
          <>
            <h1 className="p-2 font-semibold">
              <span className="text-2xl md:text-4xl">S</span>
              <span className="text-lg md:text-2xl">hipping Information</span>
            </h1>

            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={renderButtons}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="bg-gray-100 p-4">
                    <div className="bg-white p-8 text-xl">
                      <p className="flex items-center">
                        <RiArrowDropDownFill
                          size={40}
                          className="text-primary"
                        />{" "}
                        Personal Information
                      </p>
                      <div className="flex justify-between flex-wrap">
                        <div className="w-full md:w-6/12 pr-2">
                          <input
                            type="text"
                            className="p-2 w-full shadow-md border"
                            placeholder="Order Name*"
                            name="order_name"
                            value={values.order_name}
                            onChange={handleChange}
                          />
                          <span className=" text-gray-400 text-sm">
                            Please enter the full name, as written in the
                            passport
                          </span>
                          <p>
                            {errors.order_name && touched.order_name && (
                              <Message message={errors.order_name} top="" />
                            )}
                          </p>
                        </div>
                        <div className="w-full md:w-6/12 pl-0 md:pl-2">
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            value={phoneCode}
                            onChange={setPhoneCode}
                            className="p-2 w-full shadow-md border"
                          />
                          <span className=" text-gray-400 text-sm">
                            Example : +7 19191919191
                          </span>
                          {phoneCode === "" && (
                            <Message message={"Phone Number is required..."} />
                          )}
                        </div>
                      </div>
                      <label className="flex items-center my-4">
                        <RiArrowDropRightFill
                          size={40}
                          className="text-primary"
                        />
                        Address
                        <input
                          type="text"
                          className="ml-2 p-2 w-full md:w-6/12 shadow-md border"
                          name="order_address"
                          value={values.order_address}
                          onChange={handleChange}
                        />
                        {errors.order_address && touched.order_address && (
                          <Message message={errors.order_address} />
                        )}
                      </label>

                      <div className="flex justify-between flex-wrap">
                        <label className="flex items-center">
                          <RiArrowDropRightFill
                            size={40}
                            className="text-primary"
                          />
                          Zipcode
                          <input
                            type="text"
                            className="ml-2 p-2 w-40 shadow-md border"
                            name="order_zip"
                            value={values.order_zip}
                            onChange={handleChange}
                          />
                          {errors.order_zip && touched.order_zip && (
                            <Message message={errors.order_zip} />
                          )}
                        </label>
                        <label className="flex items-center w-full md:w-8/12 my-4">
                          <RiArrowDropRightFill
                            size={40}
                            className="text-primary"
                          />
                          Email
                          <input
                            type="email"
                            className="ml-2 p-2 w-full shadow-md border"
                            name="order_email"
                            onChange={handleChange}
                            value={values.order_email}
                          />
                          {errors.order_email && touched.order_email && (
                            <Message message={errors.order_email} />
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-center items-cente mt-4">
                      <div className=" bg-white p-4 rounded-md shadow-md w-full md:w-4/12">
                        <h1 className="text-4xl border-b">Order Summery</h1>
                        <div className="flex justify-evenly text-3xl py-4 font-bold">
                          <p>Total</p>
                          <p>${_isNaN(sum) ? 0.0 : sum?.toFixed(2)}</p>
                        </div>
                        <div className="flex justify-center">
                          <div>
                            <button
                              type="submit"
                              className=" bg-primary flex justify-center items-center text-white text-2xl px-4 py-2 rounded-md shadow-md w-56"
                            >
                              <span className="-mt-2">Place Order</span>
                            </button>
                            <div className="mt-6" ref={paypal}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </Layout>
  );
}

const Message = ({ message }) => {
  return <p className="text-red-400 text-sm">{message}</p>;
};

export default Shipping;
