import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};
const navSlice = createSlice({
  initialState,
  name: "navbar",
  reducers: {
    openNav: (state) => {
      return {
        ...state,
        open: true,
      };
    },
    closeNav: (state) => {
      return {
        ...state,
        open: false,
      };
    },
  },
});

export const { closeNav, openNav } = navSlice.actions;

export default navSlice.reducer;
