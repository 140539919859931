import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const OrderApi = createApi({
  reducerPath: "OrderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["order"],
  endpoints: (builder) => ({
    addOrder: builder.mutation({
      query: (body) => ({ url: "/order", method: "POST", body }),
      invalidatesTags: ["order"],
    }),
    addOrderDetail: builder.mutation({
      query: (body) => ({ url: `/order-detail`, method: "POST", body }),
      invalidatesTags: ["order"],
    }),
    getOrders: builder.mutation({
      query: (user_idx) => ({
        url: `/order?user_idx=${user_idx}`,
        method: "GET",
      }),
      invalidatesTags: ["order"],
    }),
    getOrderDetail: builder.mutation({
      query: (order_idx) => ({
        url: `/order-detail?order_idx=${order_idx}`,
        method: "GET",
      }),
      providesTags: ["order"],
    }),
  }),
});

export const {
  useAddOrderMutation,
  useAddOrderDetailMutation,
  useGetOrdersMutation,
  useGetOrderDetailMutation,
} = OrderApi;
