import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import ErrorMessage from "../components/inputs/errorMessage";
import PrimaryButton from "../components/buttons/primary";
import TextInput from "../components/inputs/textInput";
import Button from "../components/buttons/button";
import countries from "../data/country.json";
import * as Yup from "yup";
import { Formik } from "formik";
import { IoIosMailUnread } from "react-icons/io";
import { useUserSignupMutation } from "../API/signInApi";
import {
  google,
  auth,
  signInWithPopup,
  GoogleAuthProvider,
} from "../services/firebase";
import { useNavigate, useSearchParams } from "react-router-dom";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  address: "",
  password: "",
  confirmPassword: "",
};

const schema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required...!"),
  lastName: Yup.string().required("Last Name is required...!"),
  country: Yup.string().required("Country is required...!"),
  address: Yup.string(),
  email: Yup.string().email().required("Email is required...!"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required...!"),
});

function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isUserNotRegister = searchParams.get("user");
  const [isGoogle, setIsGoogle] = useState(false);
  const [fbError, seError] = useState();
  const [signUp, signUpResult] = useUserSignupMutation();

  const handleSignupHandler = async (values, { setSubmitting }) => {
    try {
      delete values?.confirmPassword;
      await signUp(values);
      setSubmitting(false);
    } catch (error) {
      console.log("ERROR IN SIGN UP");
    }
  };
  const googleAuth = () => {
    signInWithPopup(auth, google)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const obj = {
          firstName: user?.displayName,
          lastName: " ",
          email: user?.email,
          country: "N/L",
          address: "N/L",
          password: user?.providerData[0]?.uid,
          provider: "google",
        };
        await signUp(obj);
        setIsGoogle(true);

        setTimeout(() => {
          navigate("/");
        }, 1500);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        seError(errorMessage);
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  const notRegisterUserMessage = () => {
    return (
      <div className="text-center text-sm text-rose-700">
        User does not exists please sign up here...!
      </div>
    );
  };

  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {isUserNotRegister && notRegisterUserMessage()}
        <p>{signUpResult?.data?.message}</p>
        <p>{fbError && fbError}</p>

        {isGoogle ? (
          <div className="flex justify-center items-center h-[80vh] w-[80vw]">
            <div>
              <p className="text-4xl text-green-700">
                Sign Up Successfully...!
              </p>
              <span className="block text-center mt-10">Redirecting...!</span>
            </div>
          </div>
        ) : signUpResult?.data?.message ? (
          <div className="flex justify-center items-center h-screen -mt-24">
            <div className="border p-3 shadow-sm rounded-md">
              <h1 className="text-4xl">
                Thanks for signing up for travel-korea
              </h1>
              <p className="flex justify-center mt-4 ">
                <IoIosMailUnread size={80} color="#62c3f5" />
              </p>
              <p className="text-center">
                Please check your inbox and confirm the email address...!
              </p>
            </div>
          </div>
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSignupHandler}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextInput
                    placeholder="First Name"
                    type="text"
                    onChange={handleChange}
                    label="First Name"
                    name="firstName"
                    value={values.firstName}
                  />
                  {errors.firstName && touched.firstName && (
                    <ErrorMessage message={errors.firstName} />
                  )}
                  <TextInput
                    placeholder="Last Name"
                    type="text"
                    onChange={handleChange}
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                  />
                  {errors.lastName && touched.lastName && (
                    <ErrorMessage message={errors.lastName} />
                  )}
                  <TextInput
                    placeholder="Email"
                    type="email"
                    onChange={handleChange}
                    name="email"
                    label="Email"
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <ErrorMessage message={errors.email} />
                  )}
                  <TextInput
                    placeholder="Password"
                    type="password"
                    onChange={handleChange}
                    name="password"
                    label="Password"
                    value={values.password}
                  />
                  {errors.password && touched.password && (
                    <ErrorMessage message={errors.password} />
                  )}
                  <TextInput
                    placeholder="Confirm Password"
                    type="password"
                    onChange={handleChange}
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                  />{" "}
                  {errors.confirmPassword && touched.confirmPassword && (
                    <ErrorMessage message={errors.confirmPassword} />
                  )}
                  <TextInput
                    placeholder="Country"
                    type="text"
                    onChange={handleChange}
                    name="country"
                    label="Country"
                    value={values.country}
                    isSelect={true}
                    data={countries.data}
                  />
                  {errors.country && touched.country && (
                    <ErrorMessage message={errors.country} />
                  )}
                  <TextInput
                    placeholder="Address"
                    type="text"
                    onChange={handleChange}
                    name="address"
                    label="Address"
                    value={values.address}
                  />
                  {errors.address && touched.address && (
                    <ErrorMessage message={errors.address} />
                  )}
                  <Button
                    text={signUpResult.isLoading ? "Loading...!" : "Sign Up"}
                    disabled={signUpResult.isLoading}
                  />
                </form>
              )}
            </Formik>
            <PrimaryButton
              className="border text-lg shadow bg-[#4385F6] my-2"
              width="w-full"
              isIcon
              text="Sign Up with google"
              onClick={googleAuth}
            />
          </>
        )}
      </div>
    </Layout>
  );
}

export default SignUp;
