import React from "react";
import { BsCart4 } from "react-icons/bs";
import ReactStars from "react-stars";
import { Link } from "react-router-dom";
import _get from "lodash/get";
import { Config } from "../../config";

function Product({ product, handleAddCart }) {
  return (
    <div>
      <Link to={`/shop/${_get(product, "idx")}`}>
        <img
          src={`${Config.static}${product?.product_image}`}
          alt={`${Config.static}${product?.product_image}`}
          className="w-full h-60 object-cover"
        />
      </Link>
      <h1 className="text-xl">{_get(product, "product_title", "")}</h1>
      <div>
        <ReactStars
          count={5}
          size={28}
          edit={false}
          value={product?.review_score}
          color2={"#ffd700"}
        />
      </div>
      <div className="flex justify-between">
        <p className="text-2xl font-bold">
          <span>$</span>
          <span className="text-3xl">
            {_get(product, "product_price_dollar", "0.00")?.toFixed(2)}
          </span>
        </p>
        <button className="block" onClick={() => handleAddCart(product)}>
          <BsCart4
            size={28}
            className="text-gray-400 hover:text-primary focus:text-primary"
          />
        </button>
      </div>
    </div>
  );
}

export default Product;
