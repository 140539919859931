import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/inputs/textInput";
import Button from "../../components/buttons/button";
import { AiOutlineFileImage } from "react-icons/ai";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import { useAddBlogMutation } from "../../API/blogaApi";
import RichTextEditor from "react-rte";

const initialValues = {
  title: "",
  content: "",
  image: "",
  short_content: "",
};

const schema = Yup.object().shape({
  title: Yup.string().required(),
  short_content: Yup.string().required().min(10).max(300),
  image: Yup.string(),
});

function AddBlog() {
  let navigate = useNavigate();
  const [content, setContent] = useState(RichTextEditor.createEmptyValue());
  const [addBlog] = useAddBlogMutation();
  let userAdmin;
  if (typeof window !== "undefined") {
    userAdmin = JSON.parse(sessionStorage.getItem("user"));
  }
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const handleUpdateBlog = async (values, { setSubmitting }) => {
    try {
      if (file) {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("image", file);
        formdata.append("title", values?.title);
        formdata.append("short_content", values?.short_content);
        formdata.append(
          "content",
          content.toString("html")?.replaceAll(`"`, `'`)
        );
        const response = await addBlog(formdata);
        setLoading(false);
        navigate("/blog?page=1");
        setMessage(response.data?.message);
        return;
      }
      setMessage("Please upload image ");
    } catch (error) {
      setMessage(JSON.stringify(error));
    }
  };

  useEffect(() => {
    if (userAdmin?.id !== "admin") {
      navigate("/");
    }
  }, [userAdmin?.id]);
  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "header-one") {
      return "rte-heading-one";
    }
    if (type === "header-two") {
      return "rte-heading-two";
    }
    if (type === "header-three") {
      return "rte-heading-three";
    }
  };
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {loading && (
          <div className="fixed top-0 left-0 flex justify-center items-center w-full h-[100vh]  z-50">
            <Loading />
          </div>
        )}
        {message}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleUpdateBlog}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                placeholder="Title"
                type="text"
                onChange={handleChange}
                label="Title"
                name="title"
                value={values.title}
              />
              {errors.title && touched.title && errors.title}
              {errors.content && touched.content && errors.content}
              <div className="flex justify-center">
                {image && <img src={image} alt={image} className="h-56 " />}
              </div>
              <div className="border rounded my-2 shadow-sm p-2">
                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpg, image/jpeg"
                  hidden
                  name="image"
                  onChange={(e) => {
                    setImage(URL.createObjectURL(e.target.files[0]));
                    setFile(e.target.files[0]);
                  }}
                />
                <label
                  htmlFor="file"
                  className="flex justify-center items-center"
                >
                  <AiOutlineFileImage />{" "}
                  <span className="-mt-1 mx-4">Upload</span>
                </label>
              </div>
              {errors.image && touched.image && errors.image}
              <TextInput
                placeholder="Short Content"
                type="text"
                onChange={handleChange}
                label="Short Content (max character 300)"
                name="short_content"
                value={values.short_content}
                isTextarea
                rows={4}
              />
              {errors.short_content &&
                touched.short_content &&
                errors.short_content}
              <label className="text-sm">Content</label>
              <div className="h-full">
                <RichTextEditor
                  value={content}
                  onChange={(value) => setContent(value)}
                  className="min-h-[300px] max-h-full"
                  blockStyleFn={myBlockStyleFn}
                />
              </div>
              <Button text="Add Blog" />
            </form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}

export default AddBlog;
