import React, { useEffect, useState } from "react";
import _map from "lodash/map";
import Pagination from "../../components/blog/pagination";
import Breadcrumb from "../../components/breadcrumb";
import Layout from "../../components/layout";
import Youtube from "../../components/youtube";
import {
  useGetVideosQuery,
  useGetVideosCategoryQuery,
  useDeleteVideoMutation,
} from "../../API/videoApi";
import Loading from "../../components/loading";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { MdPostAdd } from "react-icons/md";

function Videos() {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  const [searchParam] = useSearchParams();
  const page = searchParam.get("page");
  const [selectCategory, setSelectCategory] = useState({
    category: "All",
    data: [],
  });
  const videos = useGetVideosQuery(page ? page : 1);
  const videosCategory = useGetVideosCategoryQuery();
  const [deleteVideoById, deleteVideoByIdResult] = useDeleteVideoMutation();
  const pages = Array?.isArray(videos?.data?.data)
    ? Math.ceil(videos?.data?.data?.length / 10)
    : 1;

  const handleCategoryClick = (category) => {
    const photosByCategory = videos?.data?.data?.filter(
      (item) => item?.category === category
    );
    setSelectCategory({ category, data: photosByCategory });
  };

  const nextPage = () => {
    if (page) {
      if (page.toString() === pages) {
        return navigate(`/media/video?page=${Number(page)}`);
      }
      if (Array.isArray(videos.data?.data)) {
        return navigate(`/media/video?page=${Number(page) + 1}`);
      }
    }
    return;
  };

  const prevPage = () => {
    if (page) {
      if (page < 2) {
        return navigate(`/media/video?page=1`);
      }
      navigate(`/media/video?page=${Number(page) - 1}`);
    }
    return;
  };
  const handlePage = (p) => {
    navigate(`/media/video?page=${p}`);
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      setUser(JSON.parse(sessionStorage.getItem("user")));
    }
  }, []);
  const handleDeleteClick = async (id) => {
    try {
      await deleteVideoById(id);
    } catch (error) {}
  };
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        <Breadcrumb
          title="Video"
          handleCategoryClick={handleCategoryClick}
          category={selectCategory.category}
          content={
            videosCategory.isSuccess
              ? [
                  { category: "All", list_order: 0 },
                  ...videosCategory.data?.data,
                ]
              : []
          }
        />
        {user?.id === "admin" && (
          <div className="flex items-center mr-4">
            <Link to={`/add/video`}>
              <span className="block bg-gray-50 border p-1 hover:bg-gray-100 active:shadow-inner focus:shadow-inner">
                <MdPostAdd size={18} />
              </span>
            </Link>
          </div>
        )}
        {videos.isLoading && (
          <div className="flex justify-center w-full">
            <Loading />
          </div>
        )}
        {deleteVideoByIdResult.isLoading && (
          <div className="flex justify-center w-full">
            <Loading />
          </div>
        )}
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
          {selectCategory.category === "All"
            ? Array.isArray(videos?.data?.data) &&
              _map(videos?.data?.data, (v, idx) => (
                <Youtube
                  key={idx}
                  video={v}
                  isAdmin={user?.id === "admin" ? true : false}
                  handleDeleteClick={handleDeleteClick}
                />
              ))
            : _map(selectCategory.data, (v, idx) => (
                <Youtube
                  key={idx}
                  video={v}
                  isAdmin={user?.id === "admin" ? true : false}
                  handleDeleteClick={handleDeleteClick}
                />
              ))}
        </div>
        <div className="my-8">
          <Pagination
            type="media"
            pages={pages}
            page={page}
            prevPage={prevPage}
            nextPage={nextPage}
            handlePage={handlePage}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Videos;
