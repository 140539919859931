import { Carousel } from "react-responsive-carousel";
import React from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Config } from "../../config";

function CarouselComponenet({ slides, index }) {
  return (
    <div className="text-white">
      <Carousel
        showArrows
        selectedItem={index}
        showIndicators
        showStatus={false}
        centerSlidePercentage={100}
        centerMode={false}
        showThumbs={false}
        renderArrowPrev={(value) => {
          return (
            <div className="cursor-pointer" onClick={value}>
              <div className="absolute top-1/2 bottom-1/2 -mt-16 -left-8 z-40">
                <IoIosArrowBack size={120} color="white" />
              </div>
              <div className="absolute top-1/2 bottom-1/2 -mt-16 -left-8 z-40">
                <IoIosArrowBack
                  size={92}
                  color="white"
                  className="opacity-50 ml-7 mt-3 pt-1"
                />
              </div>
            </div>
          );
        }}
        renderArrowNext={(value) => {
          return (
            <div className="cursor-pointer" onClick={value}>
              <div className="absolute top-1/2 bottom-1/2 -mt-16 -right-8 z-40">
                <IoIosArrowForward size={120} color="white" />
              </div>
              <div className="absolute top-1/2 bottom-1/2 -mt-16 -right-8 z-40">
                <IoIosArrowForward
                  size={92}
                  color="white"
                  className="opacity-50 mr-7 mt-3 pt-1"
                />
              </div>
            </div>
          );
        }}
        width="100%"
      >
        {slides?.map((item) => (
          <Slide item={item} key={item?.idx} />
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselComponenet;

const Slide = ({ item }) => {
  return (
    <div className="w-full h-[100vh] shadow">
      <img
        alt={item?.idx}
        src={`${Config.server}${item?.image}`}
        className="object-contain w-full h-full"
      />
    </div>
  );
};
