import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { BsCart4 } from "react-icons/bs";
import { FaRegNewspaper } from "react-icons/fa";
import _className from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { openNav } from "./navSlice";
import { AiOutlineMenu } from "react-icons/ai";
import { useGetNavbarQuery } from "../../API/navbarApi";
import { useLocation, Link } from "react-router-dom";
import Loading from "../loading";

function Navbar() {
  const { open } = useSelector((state) => state.navbar);
  const [user, setUser] = useState();
  const router = useLocation();

  const { data, isError, isLoading, error } = useGetNavbarQuery();
  const topNav = data?.data?.filter((item) => item?.parent_idx === 0);

  const [scroll, setScroll] = useState(false);
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const wrapperRef = useRef(null);
  const [hover, setHover] = useState(null);
  const scrollTopBottom = () => {
    if (typeof window !== "undefined") {
      if (router.pathname === "/") {
        window.addEventListener("scroll", () => {
          if (window.scrollY > 100) {
            setScroll(true);
          }
          if (window.scrollY < 100) {
            setScroll(false);
          }
        });
      } else {
        setScroll(true);
      }
    }
  };

  useEffect(() => {
    scrollTopBottom();
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const User = JSON.parse(sessionStorage.getItem("user"));
      if (!User) {
        setUser(null);
      }
      setUser(User);
    }
  }, [open]);

  const handleSignInClick = () => {
    dispatch(openNav());
  };

  const logout = () => {
    sessionStorage.clear();
    setUser(null);
  };

  const onHover = () => {
    if (open) return;
    setHover("hover");
  };

  const openAndCloseMobileNav = () => setMenu(!menu);

  return (
    <div
      ref={wrapperRef}
      style={{ zIndex: 99999 }}
      className={_className(
        {
          "bg-primary": scroll,
          "bg-transparent": !scroll,
        },
        "fixed top-0 z-50 p-4 w-full text-white"
      )}
    >
      {isError && error.data?.error?.message}
      <div className="flex justify-between items-center container mx-auto">
        <Link to="/">
          <span className="mr-20">
            <img src="/image/ICON_Main_Logo.png" alt="LOGO" />
          </span>
        </Link>
        {/* mobile nav */}
        <div className="flex lg:hidden">
          <AiOutlineMenu
            color="white"
            size={40}
            onClick={openAndCloseMobileNav}
          />
        </div>
        {/* desktop nav */}

        {isLoading ? (
          <Loading />
        ) : (
          topNav?.map((route, idx) => (
            <div key={idx} className="relative hidden lg:flex ">
              <p
                className="font-bold drop-shadow-2xl nav-text-shadow hidden lg:flex "
                onMouseOver={onHover}
                onMouseLeave={() => console.log("MOUSE LEAVE")}
              >
                {route?.menu_name}
              </p>
              {hover && (
                <div
                  style={{
                    zIndex: "8888",
                    borderBottom: "1px solid rgba(50,50,50,0.2)",
                    boxShadow: "#d4d4d4 0px 6px 6px -4px",
                    width: route?.menu_name === "Emergency Contact" && "173px",
                  }}
                  onMouseOver={onHover}
                  onMouseLeave={() => setHover(null)}
                  className={_className(
                    {
                      "border-l-2": route?.menu_name?.includes("INFORMATION")
                        ? true
                        : false,
                      "border-r-2": route?.menu_name?.includes("Emergency")
                        ? true
                        : false,
                    },
                    "absolute  top-14 -left-4 px-3 z-50 py-2 bg-white lg:h-[835px] xl:h-[560px] xl:w-72 lg:w-[170px] text-black"
                  )}
                >
                  {data?.data.map((item, idx) => {
                    if (item.parent_idx === route.idx) {
                      return (
                        <HrefLink
                          text={item?.menu_name}
                          link={item?.url}
                          blog_idx={item?.blog_idx}
                          key={idx}
                        />
                      );
                    }
                  })}
                </div>
              )}
            </div>
          ))
        )}
        {user ? (
          <div>
            <button className="rounded-2xl border-2 px-2 pb-1" onClick={logout}>
              Logout
            </button>
            <div className="flex justify-evenly">
              <Link to={{ pathname: "/cart", search: `?user=${user?.idx}` }}>
                <BsCart4 size={24} className="text-white" />
              </Link>
              <Link to="/order-history">
                <span>
                  <FaRegNewspaper size={24} className="text-white" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          <button
            onClick={handleSignInClick}
            className="font-bold nav-text-shadow border-2 px-6 mr-4 lg:mr-0 rounded-2xl hidden lg:flex justify-center items-center"
          >
            <span className="pb-2 pt-1 px-2">Sign In</span>
          </button>
        )}
      </div>

      {menu && (
        <MobileNavbar
          handleSignInClick={handleSignInClick}
          routes={data?.data}
          topNav={topNav}
        />
      )}
    </div>
  );
}

const MobileNavbar = ({ handleSignInClick, routes, topNav }) => {
  const [hover, setHover] = useState();
  return (
    <div className="text-black bg-white w-full lg:hidden p-4 rounded-lg relative">
      {topNav?.map((route, idx) => (
        <Link to={route.url} key={idx}>
          <span
            className="font-bold overflow-hidden py-2 block"
            onClick={() => setHover(route?.idx)}
          >
            {route?.menu_name}
          </span>
        </Link>
      ))}
      <button
        onClick={handleSignInClick}
        className="font-bold bg-primary text-white border-2 px-6 py-3 mr-4 rounded-2xl"
      >
        Sign In
      </button>
      {hover && (
        <div className="mobile-nav text-black border p-4 rounded-lg shadow-lg w-9/12">
          {routes?.map(({ parent_idx, menu_name, url, idx, blog_idx }, id) => {
            if (parent_idx === hover) {
              return (
                <div key={id}>
                  {url?.includes("https://") ? (
                    <a href={url} target="_blank">
                      <span className="block border-b font-xl py-2">
                        {menu_name}
                      </span>
                    </a>
                  ) : (
                    <Link to={blog_idx ? `${url}id=${blog_idx}` : `${url}`}>
                      <span className="block border-b font-xl py-2">
                        {menu_name}
                      </span>
                    </Link>
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default Navbar;

const HrefLink = ({ text, link, blog_idx }) => {
  return (
    <>
      {link?.includes("https://") ? (
        <a href={link} target="_blank">
          <span className="block text-lg w-24 xl:w-56 2xl:w-56 p-1 hover:text-gray-500">
            {text}
          </span>
        </a>
      ) : (
        <Link to={blog_idx ? `${link}id=${blog_idx}` : `${link}`}>
          <span className="block text-lg w-24 xl:w-56 2xl:w-56 p-1 hover:text-gray-500">
            {text}
          </span>
        </Link>
      )}
    </>
  );
};
