import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const photoApi = createApi({
  reducerPath: "photoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["photos"],
  endpoints: (builder) => ({
    getPhotos: builder.query({
      query: (page = 1) => ({ url: `/photos?page=${page}` }),
      providesTags: ["photos"],
    }),
    getPhotosCategory: builder.query({
      query: () => ({ url: "/photos-category" }),
      providesTags: ["photos"],
    }),
    addPhoto: builder.mutation({
      query: (body) => ({ url: `/photos`, body, method: "POST" }),
      invalidatesTags: ["photos"],
    }),
    deletePhoto: builder.mutation({
      query: (id) => ({ url: `/photos/${id}`, method: "DELETE" }),
      invalidatesTags: ["photos"],
    }),
    getPhoto: builder.query({
      query: (id) => ({ url: `/photos/${id}` }),
      providesTags: ["photos"],
    }),
  }),
});

export const {
  useGetPhotosCategoryQuery,
  useGetPhotosQuery,
  useGetPhotoQuery,
  useDeletePhotoMutation,
  useAddPhotoMutation,
} = photoApi;
