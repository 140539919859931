import React from "react";
import _classNames from "classnames";
import _sortBy from "lodash/sortBy";
import _split from "lodash/split";
import _slice from "lodash/slice";
import _map from "lodash/map";

function Breadcrumb({ title, content, handleCategoryClick, category }) {
  return (
    <div className="flex justify-start items-center flex-wrap my-2 w-full">
      <div className="p-2 font-semibold pr-5 lg:pr-24">
        <span className="text-2xl md:text-4xl">{_split(title, "", 1)}</span>
        <span className="text-lg md:text-2xl">
          {_slice(_split(title, ""), 1)}
        </span>
      </div>
      {_map(
        _sortBy(content, (o) => o?.list_order),
        (c) => (
          <div
            onClick={() =>
              handleCategoryClick(title === "Shop" ? c.idx : c.category)
            }
            key={title === "Shop" ? c.title : c.category}
            className={_classNames(
              title === "Shop"
                ? {
                    "text-primary": c?.idx === category,
                  }
                : {
                    "text-primary": c?.category === category,
                  },
              "mt-2 text-md md:text-lg pr-5 lg:pr-24 cursor-pointer"
            )}
          >
            {title === "Shop" ? c.title : c.category}
          </div>
        )
      )}
    </div>
  );
}

export default Breadcrumb;
