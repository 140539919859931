import React, { useEffect } from "react";
import MyPlain from "../../components/forms/myPlain";
import Layout from "../../components/layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetBlogsQuery } from "../../API/blogaApi";
import BlogCard from "../../components/blog/index";
import Loading from "../../components/loading";
import _map from "lodash/map";
import { HiOutlineNewspaper } from "react-icons/hi";
import Pagination from "../../components/blog/pagination";
import { MdPostAdd } from "react-icons/md";
import { Link } from "react-router-dom";

function Blogs() {
  let userAdmin;
  if (typeof window !== "undefined") {
    userAdmin = JSON.parse(sessionStorage.getItem("user"));
  }
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  const { data, isLoading, isError, isSuccess, error } = useGetBlogsQuery(
    page ? page : 1
  );

  const pages = Array?.isArray(data?.data) ? Math.ceil(data?.count / 10) : 1;
  const nextPage = () => {
    if (page) {
      if (page == pages) {
        return navigate(`/blog?page=${Number(page)}`);
      }
      if (Array.isArray(data?.data)) {
        return navigate(`/blog?page=${Number(page) + 1}`);
      }
    }
    return;
  };

  const prevPage = () => {
    if (page) {
      if (page < 2) {
        return navigate(`/blog?page=1`);
      }
      navigate(`/blog?page=${Number(page) - 1}`);
    }
    return;
  };
  const handlePage = (p) => {
    navigate(`/blog?page=${p}`);
  };
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {userAdmin?.id === "admin" && (
          <Link to="/add/blog">
            <MdPostAdd size={18} className="border shadow rounded" />
          </Link>
        )}

        <div className="flex justify-start flex-wrap w-full">
          <div className="w-full md:w-8/12 lg:w-9/12">
            <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2">
              {isError && JSON.stringify(error)}
              {isLoading ? (
                <Loading />
              ) : isSuccess && Array.isArray(data?.data) ? (
                _map(data?.data, (blog) => (
                  <div className="m-2" key={blog?.idx}>
                    <BlogCard blog={blog} />
                  </div>
                ))
              ) : (
                <div className="w-[100vw] md:w-[75vw] flex justify-center items-center">
                  <div>
                    <span className="flex justify-center">
                      <HiOutlineNewspaper size={100} color="#62c3f5" />
                    </span>
                    <h1 className="text-4xl text-[#62c3f5]"> Blogs empty</h1>
                  </div>
                </div>
              )}
            </div>
            <Pagination
              type="blog"
              pages={pages}
              page={page}
              prevPage={prevPage}
              nextPage={nextPage}
              handlePage={handlePage}
            />
          </div>

          <div className="w-full md:w-4/12 lg:w-3/12 mt-2">
            <MyPlain />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Blogs;
