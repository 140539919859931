import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const navbarApi = createApi({
  reducerPath: "navbarApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["navbar"],
  endpoints: (builder) => ({
    getNavbar: builder.query({
      query: () => ({ url: "/main" }),
    }),
  }),
});

export const { useGetNavbarQuery } = navbarApi;
