import { Carousel } from "react-responsive-carousel";
import React from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useGetSlidesQuery } from "../../API/slideApi";
import { Config } from "../../config";
import Loading from "../loading";

function CarouselComponenet() {
  const { data, isLoading } = useGetSlidesQuery();

  const PrevArrow = (value) => {
    return (
      <div className="cursor-pointer" onClick={value}>
        <div className="absolute top-1/2 bottom-1/2 -left-7 md:left-[10%] z-40">
          <IoIosArrowBack size={120} color="white" />
        </div>
        <div className="absolute top-1/2 bottom-1/2 -left-7 md:left-[10%] z-40">
          <IoIosArrowBack
            size={92}
            color="white"
            className="opacity-50 ml-7 mt-3 pt-1"
          />
        </div>
      </div>
    );
  };
  const NextArrow = (value) => {
    return (
      <div className="cursor-pointer" onClick={value}>
        <div className="absolute top-1/2 bottom-1/2 -right-7 md:right-[10%] z-40">
          <IoIosArrowForward size={120} color="white" />
        </div>
        <div className="absolute top-1/2 bottom-1/2 -right-7 md:right-[10%] z-40">
          <IoIosArrowForward
            size={92}
            color="white"
            className="opacity-50 mr-7 mt-3 pt-1"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="text-white">
      {isLoading ? (
        <div className="w-full h-[500px] text-black flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <Carousel
          autoPlay={true}
          showArrows
          infiniteLoop
          showIndicators
          showStatus={false}
          centerSlidePercentage={100}
          centerMode={false}
          showThumbs={false}
          renderArrowPrev={PrevArrow}
          renderArrowNext={NextArrow}
          width="100%"
        >
          {data?.data?.map((item) => (
            <Slide item={item} key={item?.idx} />
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default CarouselComponenet;

const Slide = ({ item }) => {
  return (
    <div className="w-full h-[500px] md:h-[600]  overflow-hidden">
      <img
        alt="KOREA"
        src={`${Config.host}${item?.filename}`}
        className="w-full object-cover h-full md:h-full lg:h-[600px] xl:h-[700px]"
      />
      <p className="absolute top-1/2 bottom-1/2 right-0 left-0 z-50 text-2xl md:text-4xl lg:text-6xl nav-text-shadow mt-2">
        {item?.title1}
        <span className="block text-2xl ">{item?.title2}</span>
      </p>
      <p className="absolute bottom-20 right-0 left-0 z-50 text-2xl nav-text-shadow mt-2">
        {item?.title3}
      </p>
    </div>
  );
};
