import React from "react";
import { Config } from "../../config";
import { Link } from "react-router-dom";

function Blog({ blog }) {
  return (
    <div className="relative w-full h-72 text-white rounded-lg shadow-lg overflow-hidden">
      <Link to={`/blog/${blog?.title}id=${blog?.idx}`}>
        <img
          src={`${Config.static}${blog?.image}`}
          alt={blog?.title}
          className="w-full h-72 object-cover "
        />
      </Link>
      <p className="absolute bottom-2 left-2 text-2xl ">{blog?.title}</p>
    </div>
  );
}

export default Blog;
