import React from "react";
import _classNames from "classnames";
import Button from "./button";

function BuyAndCartButton({ handleCartClick, handleBuyClick }) {
  return (
    <div className="text-right bg-gray-100 p-3 rounded-lg">
      <Button text="Add to Cart" onClick={handleCartClick} />
      <Button text="Buy it Now" bgColor="cart" onClick={handleBuyClick} />
    </div>
  );
}

export default BuyAndCartButton;
