import React, { useState, useEffect } from "react";
import Layout from "../../../components/layout";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../../components/inputs/textInput";
import Button from "../../../components/buttons/button";
import { useParams, useNavigate } from "react-router-dom";
import { useGetBlogQuery } from "../../../API/blogaApi";
import { AiOutlineFileImage } from "react-icons/ai";
import { Config } from "../../../config";
import Loading from "../../../components/loading";
import RichTextEditor from "react-rte";
const initialValues = {
  title: "",
  content: "",
  image: "",
  short_content: "",
};

const schema = Yup.object().shape({
  title: Yup.string().required(),
  short_content: Yup.string().required().min(10).max(300),
  image: Yup.string(),
});

function UpdateBlog() {
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [content, setContent] = useState(RichTextEditor.createEmptyValue());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const blog = useGetBlogQuery(id);
  let userAdmin;
  if (typeof window !== "undefined") {
    userAdmin = JSON.parse(sessionStorage.getItem("user"));
  }
  useEffect(() => {
    if (userAdmin?.id !== "admin") {
      navigate("/");
    }
  }, []);
  const handleUpdateBlog = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      if (values) {
        let FD = new FormData();
        FD.append("image", file ? file : values?.image);
        FD.append("title", values?.title);
        FD.append("short_content", values?.short_content);
        FD.append("content", content.toString("html")?.replaceAll(`"`, `'`));

        const response = await fetch(
          `${Config.server}/blogs/${blog.data?.data[0]?.idx}`,
          {
            method: "PUT",
            body: FD,
          }
        );
        setLoading(false);
        setMessage(response?.data?.message);
        window.location.href = "/blog?page=1";
      }
    } catch (error) {
      setMessage(JSON.stringify(error));
    }
  };

  if (blog?.data?.data?.length) {
    const blg = blog?.data?.data[0];
    initialValues.title = blg?.title;
    initialValues.short_content = blg?.short_content;
    initialValues.image = blg?.image;
  }
  useEffect(() => {
    setContent(
      RichTextEditor.createValueFromString(blog?.data?.data[0].content, "html")
    );
  }, [blog.data]);
  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "header-one") {
      return "rte-heading-one";
    }
    if (type === "header-two") {
      return "rte-heading-two";
    }
    if (type === "header-three") {
      return "rte-heading-three";
    }
  };
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {loading && <Loading />}
        {message}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleUpdateBlog}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                placeholder="Title"
                type="text"
                onChange={handleChange}
                label="Title"
                name="title"
                value={values.title}
              />
              {errors.title && touched.title && errors.title}
              <div className="flex justify-center">
                {file ? (
                  <img src={image} alt={image} className="h-56 " />
                ) : (
                  <img
                    src={`${Config.static}${blog?.data?.data[0]?.image}`}
                    alt={`${Config.static}${blog?.data?.data[0]?.image}`}
                    className="h-56 "
                  />
                )}
              </div>
              <div className="border rounded my-2 shadow-sm p-2">
                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpg, image/jpeg"
                  hidden
                  name="image"
                  onChange={(e) => {
                    setImage(URL.createObjectURL(e.target.files[0]));
                    setFile(e.target.files[0]);
                  }}
                />
                <label
                  htmlFor="file"
                  className="flex justify-center items-center"
                >
                  <AiOutlineFileImage />
                  <span className="-mt-1 mx-4">Upload</span>
                </label>
                {errors.image && touched.image && errors.image}
              </div>
              <TextInput
                placeholder="Short Content"
                type="text"
                onChange={handleChange}
                label="Short Content (max character 300)"
                name="short_content"
                value={values.short_content}
                isTextarea
                rows={4}
              />
              {errors.short_content &&
                touched.short_content &&
                errors.short_content}
              <label className="text-sm">Content</label>
              <div className="h-full">
                <RichTextEditor
                  value={content}
                  onChange={(value) => setContent(value)}
                  className="min-h-[300px] max-h-full"
                  blockStyleFn={myBlockStyleFn}
                />
              </div>

              <Button text="Update Blog" />
            </form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}

export default UpdateBlog;
