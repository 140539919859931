import { Routes, Route } from "react-router-dom";
import AddBlog from "./pages/add/blog";
import AddPhoto from "./pages/add/photo";
import AddProduct from "./pages/add/product";
import AddVideo from "./pages/add/video";
import Blogs from "./pages/blog";
import SingleBlog from "./pages/blog/blog";
import VerifyEmail from "./pages/confirm-email";
import ForgetPassword from "./pages/forget-password";
import Home from "./pages/Home";
import Photos from "./pages/media/photo";
import Videos from "./pages/media/video";
import OrderHistory from "./pages/order-history";
import Shop from "./pages/shop";
import Cart from "./pages/shop/cart";
import Shipping from "./pages/shop/cart/shipping";
import ProductDetails from "./pages/shop/productDetail";
import SignUp from "./pages/signup";
import UpdateBlog from "./pages/update/blog";
import UpdatePhoto from "./pages/update/photo";
import UpdateProduct from "./pages/update/product";
import UpdateVideo from "./pages/update/video";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/media/video" element={<Videos />} />
        <Route path="/media/photo" element={<Photos />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:id" element={<ProductDetails />} />
        <Route path="/confirm-email/:id" element={<VerifyEmail />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:id" element={<SingleBlog />} />
        <Route path="/add/blog" element={<AddBlog />} />
        <Route path="/add/photo" element={<AddPhoto />} />
        <Route path="/add/video" element={<AddVideo />} />
        <Route path="/add/product" element={<AddProduct />} />
        <Route path="/update/product/:id" element={<UpdateProduct />} />
        <Route path="/update/blog/:id" element={<UpdateBlog />} />
        <Route path="/update/video/:id" element={<UpdateVideo />} />
        <Route path="/update/photo/:id" element={<UpdatePhoto />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </div>
  );
}

export default App;
