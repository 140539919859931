import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const blogApi = createApi({
  reducerPath: "blogsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["blogs"],
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: (page = 0) => ({ url: `/blogs?page=${page}` }),
      providesTags: ["blogs"],
    }),
    getBlog: builder.query({
      query: (title) => ({ url: `/blogs/${title}` }),
      providesTags: ["blogs"],
    }),
    addBlog: builder.mutation({
      query: (body) => ({ url: `/blogs/`, body, method: "POST" }),
      invalidatesTags: ["blogs"],
    }),
    updateBlog: builder.mutation({
      query: (body) => {
        return {
          url: `/blogs/${body.id}`,
          body: body?.data,
          method: "PUT",
        };
      },
      invalidatesTags: ["blogs"],
    }),
    deleteBlog: builder.mutation({
      query: (id) => ({ url: `/blogs/${id}`, method: "DELETE" }),
      invalidatesTags: ["blogs"],
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useGetBlogQuery,
  useAddBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
} = blogApi;
