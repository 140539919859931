import { configureStore } from "@reduxjs/toolkit";
import navbar from "../components/navbar/navSlice";
import cart from "../components/product/addToCartSlice";
import { slideApi } from "../API/slideApi";
import { navbarApi } from "../API/navbarApi";
import { blogApi } from "../API/blogaApi";
import { photoApi } from "../API/photoApi";
import { videoApi } from "../API/videoApi";
import { shopApi } from "../API/shopApi";
import { newsApi } from "../API/newsApi";
import { signInApi } from "../API/signInApi";
import { cartApi } from "../API/cartApi";
import { OrderApi } from "../API/orderApi";
import { CountryApi } from "../API/countryApi";
import { tripApi } from "../API/tripApi";

export const store = configureStore({
  reducer: {
    [slideApi.reducerPath]: slideApi.reducer,
    [navbarApi.reducerPath]: navbarApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [photoApi.reducerPath]: photoApi.reducer,
    [videoApi.reducerPath]: videoApi.reducer,
    [shopApi.reducerPath]: shopApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [signInApi.reducerPath]: signInApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [CountryApi.reducerPath]: CountryApi.reducer,
    [tripApi.reducerPath]: tripApi.reducer,
    navbar,
    cart,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(slideApi.middleware)
      .concat(signInApi.middleware)
      .concat(blogApi.middleware)
      .concat(photoApi.middleware)
      .concat(videoApi.middleware)
      .concat(shopApi.middleware)
      .concat(tripApi.middleware)
      .concat(newsApi.middleware)
      .concat(cartApi.middleware),
});
