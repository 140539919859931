import React, { useEffect, useState } from "react";
import CartItems from "../../components/cart/cartItems";
import Layout from "../../components/layout";
import { useGetOrdersMutation } from "../../API/orderApi";
import Loading from "../../components/loading";
import _groupBy from "lodash/groupBy";

function OrderHistory() {
  const [orders, setOrders] = useState([]);
  const [getOrders, orderState] = useGetOrdersMutation();

  async function getHandleFunc() {
    if (typeof window !== "undefined") {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const response = await getOrders(user?.idx);
      setOrders(response.data?.data);
    }
  }

  const orderGroupByOrderIdx =
    Array.isArray(orders) && _groupBy(orders, (order) => order?.order_idx);
  const orderCount = Object.keys(orderGroupByOrderIdx);

  useEffect(() => {
    getHandleFunc();
  }, []);

  return (
    <Layout>
      <div className="container mx-auto mt-32">
        <h1 className="p-2 font-semibold ">
          <span className="text-2xl md:text-4xl">O</span>
          <span className="text-lg md:text-2xl">rder History</span>
        </h1>

        <h1 className="px-2">
          <span className="text-xl ">Orders ({orderCount?.length})</span>
        </h1>
        <div className="bg-gray-100 grid grid-flow-row lg:grid-flow-col grid-cols-1 lg:grid-cols-3">
          {orderState.isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="col-span-2">
                <CartItems type="history" orders={orders} />
              </div>
            </>
          )}
          <div className="bg-gray-100 ">Ads</div>
        </div>
      </div>
    </Layout>
  );
}

export default OrderHistory;
