import React from "react";
import { FaChevronRight } from "react-icons/fa";

function Button({
  text,
  isIcon,
  type = "submit",
  handleClick,
  disabled = false,
  ...props
}) {
  return (
    <button
      onClick={handleClick}
      type={type}
      disabled={disabled}
      {...props}
      className="bg-primary text-white text-center text-lg w-full rounded-md shadow-md p-2 flex justify-center items-center"
    >
      <span className="mx-8 -mt-1">{text}</span>
      {isIcon && <FaChevronRight color="white" size={20} />}
    </button>
  );
}

export default Button;
