import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const signInApi = createApi({
  reducerPath: "signInApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),

  tagTypes: ["user"],
  endpoints: (builder) => ({
    userSignIn: builder.mutation({
      query: (body) => {
        return {
          url: "/login-user",
          method: "POST",
          body,
        };
      },
    }),
    userSignup: builder.mutation({
      query: (body) => {
        return {
          url: "/signup-user",
          method: "POST",
          body,
        };
      },
    }),
    userForgetPassword: builder.mutation({
      query: (body) => {
        return {
          url: "/forget-password",
          method: "POST",
          body,
        };
      },
    }),
    userVerifyAccount: builder.query({
      query: (id) => ({
        url: `/verified-user/${id}`,
      }),
    }),
  }),
});

export const {
  useUserSignInMutation,
  useUserSignupMutation,
  useUserVerifyAccountQuery,
  useUserForgetPasswordMutation,
} = signInApi;
