import React from "react";
import { Config } from "../../config";
import { Link } from "react-router-dom";

function News({ item, type, scrollToTop }) {
  return (
    <Link
      to={type === "news" ? "#" : `/blog/${item?.title}id=${item?.idx}`}
      onClick={scrollToTop}
    >
      <div className="flex flex-wrap w-full p-2 border-t">
        <div className="w-full md:w-4/12 lg:w-4/12">
          <img
            src={`${Config.static}${item?.image}`}
            alt={`${Config.static}${item?.image}`}
            className="w-full h-60 object-cover"
          />
        </div>
        <div className="w-full md:w-8/12 lg:w-8/12 px-8">
          <h1 className=" text-2xl mt-4">{item?.title}</h1>
          <div className="mt-4 text-gray-400">{item?.short_content}</div>
        </div>
      </div>
    </Link>
  );
}

export default News;
