import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/inputs/textInput";
import Button from "../../components/buttons/button";
import { AiOutlineFileImage } from "react-icons/ai";
import { Config } from "../../config";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import { useGetShopProductsCategoryQuery } from "../../API/shopApi";
import RichTextEditor from "react-rte";

const initialValues = {
  product_title: "",
  product_price_dollar: "",
  product_actual_weight_kg: "",
  product_width_cm: "",
  product_length_cm: "",
  product_height_cm: "",
  inventory_count: "",
  category_idx: 1,
};

const schema = Yup.object().shape({
  product_title: Yup.string().required(),
  product_price_dollar: Yup.number().required(),
  product_actual_weight_kg: Yup.number().required(),
  product_width_cm: Yup.number().required(),
  product_length_cm: Yup.number().required(),
  product_height_cm: Yup.number().required(),
  inventory_count: Yup.number().required(),
  category_idx: Yup.number().required().default(1),
});

function AddProduct() {
  let navigate = useNavigate();
  let userAdmin;
  if (typeof window !== "undefined") {
    userAdmin = JSON.parse(sessionStorage.getItem("user"));
  }
  const shopProductsCategory = useGetShopProductsCategoryQuery();
  const [content, setContent] = useState(RichTextEditor.createEmptyValue());
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const handleAddProduct = async (values, { setSubmitting }) => {
    try {
      if (content.toString("html") !== "") {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("product_image", file);
        formdata.append("product_title", values?.product_title);
        formdata.append(
          "product_description",
          content.toString("html")?.replaceAll(`"`, `'`)
        );
        formdata.append("product_price_dollar", values?.product_price_dollar);
        formdata.append(
          "product_actual_weight_kg",
          values?.product_actual_weight_kg
        );
        formdata.append("product_width_cm", values?.product_width_cm);
        formdata.append("product_length_cm", values?.product_length_cm);
        formdata.append("product_height_cm", values?.product_height_cm);
        formdata.append("inventory_count", values?.inventory_count);
        formdata.append("category_idx", values?.category_idx);
        const product = await fetch(`${Config.server}/shops`, {
          method: "POST",
          body: formdata,
        });
        const result = await product.json();

        setLoading(false);
        setMessage(result?.message);
        navigate("/shop");
        return;
      }
      setMessage("Please write content ");
    } catch (error) {
      setMessage(JSON.stringify(error));
    }
  };

  useEffect(() => {
    if (userAdmin?.id !== "admin") {
      navigate("/");
    }
  }, [userAdmin?.id]);
  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "header-one") {
      return "rte-heading-one";
    }
    if (type === "header-two") {
      return "rte-heading-two";
    }
    if (type === "header-three") {
      return "rte-heading-three";
    }
  };
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {loading && (
          <div className="fixed top-0 left-0 flex justify-center items-center w-full h-[100vh]  z-50">
            <Loading />
          </div>
        )}
        {message}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleAddProduct}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                placeholder="Title"
                type="text"
                onChange={handleChange}
                label="Title"
                name="product_title"
                value={values.product_title}
              />
              {errors.product_title &&
                touched.product_title &&
                errors.product_title}
              {/* <TextInput
                placeholder="Description"
                type="text"
                onChange={handleChange}
                name="product_description"
                label="Description"
                value={values.product_description}
                isTextarea
                rows={6}
              /> */}
              <label className="text-sm">Description</label>
              <div className="h-full">
                <RichTextEditor
                  value={content}
                  onChange={(value) => setContent(value)}
                  className="min-h-[300px] max-h-full"
                  blockStyleFn={myBlockStyleFn}
                />
              </div>
              {errors.product_description &&
                touched.product_description &&
                errors.product_description}

              <TextInput
                placeholder="0.00"
                type="number"
                onChange={handleChange}
                label="Price in doller"
                name="product_price_dollar"
                value={values.product_price_dollar}
              />
              {errors.product_price_dollar &&
                touched.product_price_dollar &&
                errors.product_price_dollar}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Weight in KG"
                name="product_actual_weight_kg"
                value={values.product_actual_weight_kg}
              />
              {errors.product_actual_weight_kg &&
                touched.product_actual_weight_kg &&
                errors.product_actual_weight_kg}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Width CM"
                name="product_width_cm"
                value={values.product_width_cm}
              />
              {errors.product_width_cm &&
                touched.product_width_cm &&
                errors.product_width_cm}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Length CM"
                name="product_length_cm"
                value={values.product_length_cm}
              />
              {errors.product_length_cm &&
                touched.product_length_cm &&
                errors.product_length_cm}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Height CM"
                name="product_height_cm"
                value={values.product_height_cm}
              />
              {errors.product_height_cm &&
                touched.product_height_cm &&
                errors.product_height_cm}

              <TextInput
                placeholder="10"
                type="number"
                onChange={handleChange}
                label="Inventory Count"
                name="inventory_count"
                value={values.inventory_count}
              />
              {errors.inventory_count &&
                touched.inventory_count &&
                errors.inventory_count}

              <TextInput
                placeholder="10"
                type="select"
                isSelect
                onChange={handleChange}
                label="Category"
                name="category_idx"
                value={values.category_idx}
                data={shopProductsCategory?.data?.data}
              />
              {errors.category_idx &&
                touched.category_idx &&
                errors.category_idx}
              <div className="flex justify-center">
                {image && <img src={image} alt={image} className="h-56 " />}
              </div>
              <div className="border rounded my-2 shadow-sm p-2">
                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpg, image/jpeg"
                  hidden
                  name="image"
                  onChange={(e) => {
                    setImage(URL.createObjectURL(e.target.files[0]));
                    setFile(e.target.files[0]);
                  }}
                />
                <label
                  htmlFor="file"
                  className="flex justify-center items-center"
                >
                  <AiOutlineFileImage />
                  <span className="-mt-1 mx-4">Upload</span>
                </label>
              </div>
              {errors.image && touched.image && errors.image}
              <Button text="Add Products" />
            </form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}

export default AddProduct;
