import React from "react";

function MyPlainBadge() {
  return (
    <div className="bg-secondary w-10 h-14 relative">
      <div className="absolute bottom-0 left-0 trapezium"></div>
    </div>
  );
}

export default MyPlainBadge;
