import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const tripApi = createApi({
  reducerPath: "tripApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["trip"],
  endpoints: (builder) => ({
    getToursWhere: builder.query({
      query: () => ({ url: `/tours-where` }),
      providesTags: ["trip"],
    }),
    getHotelsCity: builder.query({
      query: () => ({ url: `/hotels-city` }),
      providesTags: ["trip"],
    }),
  }),
});

export const { useGetToursWhereQuery, useGetHotelsCityQuery } = tripApi;
