import React from "react";
import _classNames from "classnames";

function Button({ text, bgColor = "buy", onClick, ...props }) {
  return (
    <button
      onClick={onClick}
      {...props}
      className={_classNames(
        {
          "bg-primary": bgColor === "buy",
          "bg-[#3047c3]": bgColor === "cart",
        },
        "pt-2 pb-4 px-4 text-3xl text-white rounded-full m-2 shadow-lg"
      )}
    >
      {text}
    </button>
  );
}

export default Button;
