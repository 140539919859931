import React from "react";

function TextInput({
  placeholder,
  type,
  value,
  name,
  onChange,
  label,
  isTextarea = false,
  isSelect = false,
  data = null,
  rows = 0,
  ...props
}) {
  return (
    <>
      <label className="text-sm">{label} </label>
      {isSelect ? (
        <select
          onChange={onChange}
          name={name}
          value={value}
          className="border rounded-md text-gray-400 shadow-sm active:bg-gray-50 focus:bg-gray-50 block w-full mb-4 pl-[4px] p-[10px] focus:outline-none"
        >
          {type === "select"
            ? data?.map((item) => (
                <option key={item?.idx} value={item?.idx}>
                  {item?.title}
                </option>
              ))
            : data?.map((country) => (
                <option key={country?.name} value={country?.name}>
                  {country?.name}
                </option>
              ))}
        </select>
      ) : (
        <>
          {isTextarea ? (
            <textarea
              {...props}
              placeholder={placeholder}
              name={name}
              rows={rows}
              value={value}
              onChange={onChange}
              className="border rounded-md shadow-sm active:bg-gray-50 focus:bg-gray-50 block w-full mb-4 p-2 focus:outline-none"
            />
          ) : (
            <input
              {...props}
              placeholder={placeholder}
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              className="border rounded-md shadow-sm active:bg-gray-50 focus:bg-gray-50 block w-full mb-4 p-2 focus:outline-none"
            />
          )}
        </>
      )}
    </>
  );
}

export default TextInput;
