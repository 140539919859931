import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/inputs/textInput";
import Button from "../../components/buttons/button";
import { AiOutlineFileImage } from "react-icons/ai";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";

import {
  useAddVideoMutation,
  useGetVideosCategoryQuery,
} from "../../API/videoApi";

const initialValues = {
  title: "",
  category: "Campaign",
  url: "",
};

const schema = Yup.object().shape({
  title: Yup.string().required(),
  url: Yup.string().required(),
  category: Yup.string().required().default("Campaign"),
});

function AddVideo() {
  let navigate = useNavigate();
  const [addVideo] = useAddVideoMutation();
  const videosCategory = useGetVideosCategoryQuery();
  let userAdmin;
  if (typeof window !== "undefined") {
    userAdmin = JSON.parse(sessionStorage.getItem("user"));
  }
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const handleUpdateBlog = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("image", file);
      formdata.append("title", values?.title);
      formdata.append("url", values?.url);
      formdata.append("category", values?.category);

      const response = await addVideo(formdata);
      setLoading(false);
      navigate("/media/video?page=1");
      setMessage(response.data?.message);
    } catch (error) {
      setMessage(JSON.stringify(error));
    }
  };

  useEffect(() => {
    if (userAdmin?.id !== "admin") {
      navigate("/");
    }
  }, [userAdmin?.id]);

  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {loading && (
          <div className="fixed top-0 left-0 flex justify-center items-center w-full h-[100vh]  z-50">
            {" "}
            <Loading />
          </div>
        )}
        {message}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleUpdateBlog}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                placeholder="Title"
                type="text"
                onChange={handleChange}
                label="Title"
                name="title"
                value={values.title}
              />
              {errors.title && touched.title && errors.title}
              <TextInput
                placeholder="https://www.youtube.com/watch?v=idx"
                type="text"
                onChange={handleChange}
                name="url"
                label="Url"
                value={values.url}
                rows={6}
              />
              {errors.url && touched.url && errors.url}
              <label className="text-sm">Category</label>
              <select
                className="w-full border p-3 shadow-sm rounded-md"
                name="category"
                onChange={handleChange}
                value={values.category}
              >
                {videosCategory.data?.data?.map((ct) => (
                  <option value={ct?.category}>{ct?.category}</option>
                ))}
              </select>
              <div className="flex justify-center">
                {image && <img src={image} alt={image} className="h-56 " />}
              </div>
              <div className="border rounded my-2 shadow-sm p-2">
                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpg, image/jpeg"
                  hidden
                  name="image"
                  onChange={(e) => {
                    setImage(URL.createObjectURL(e.target.files[0]));
                    setFile(e.target.files[0]);
                  }}
                />
                <label
                  htmlFor="file"
                  className="flex justify-center items-center"
                >
                  <AiOutlineFileImage />{" "}
                  <span className="-mt-1 mx-4">Upload</span>
                </label>
              </div>
              {errors.image && touched.image && errors.image}
              <Button text="Add Video" />
            </form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}

export default AddVideo;
