import React, { useState } from "react";
import Button from "../buttons/button";
import _sortBy from "lodash/sortBy";
import DateInput from "../inputs/date";
import Select from "../inputs/select";
import cities from "../../data/cities.json";
import { useGetToursWhereQuery } from "../../API/tripApi";

function Tour() {
  const [city, setCity] = useState(
    "https://www.viator.com/tours/Seoul/Korean-Cooking-…al-and-Local-Market-Tour/d973-121170P1?mcid=61846"
  );
  const toursWhere = useGetToursWhereQuery();
  const sortedTours = _sortBy(toursWhere.data, "list_order");
  const handleFind = () => {
    window.open(city, "_blank");
  };
  return (
    <div>
      <div className="mt-2">
        <Select
          label="Where"
          isIcon
          data={sortedTours}
          handleChange={(e) => setCity(e.target.value)}
        />
      </div>
      <div className="mt-2">
        <Button text="Find your Tour" isIcon handleClick={handleFind} />
      </div>
    </div>
  );
}

export default Tour;
