import React from "react";
import Layout from "../../components/layout";
import { useUserVerifyAccountQuery } from "../../API/signInApi";
import { useParams, useNavigate } from "react-router-dom";

function VerifyEmail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isError, isSuccess, error, isLoading } =
    useUserVerifyAccountQuery(id);
  if (isSuccess) {
    setTimeout(() => {
      navigate("/");
    }, 1000);
  }
  return (
    <Layout>
      <div className="flex justify-center items-center h-[88vh]">
        {isError && <span>{JSON.stringify(error)}</span>}
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className="border p-3 shadow-sm rounded-md text-center">
            <h1 className="text-4xl text-green-700 font-bold">
              Your registration is complete.
              <p> Welcome.&#129309;</p>
            </h1>
            {isSuccess && (
              <p className="mt-8 text-gray-400 text-xs">Redirecting...</p>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}

export default VerifyEmail;
