import React from "react";
import CartItem from "./item";
import _groupBy from "lodash/groupBy";

function CartItems({
  type,
  data,
  handleRemoveCart,
  selectAll,
  selectItems,
  select,
  quantityDecrement,
  quantityIncrement,
  orders,
}) {
  const orderGroupByOrderIdx =
    Array.isArray(orders) && _groupBy(orders, (order) => order?.order_idx);
  const orderKeys = Object.keys(orderGroupByOrderIdx);

  return (
    <div className="m-2 rounded-lg bg-white">
      {type === "history" ? (
        orderKeys?.map((item) => {
          return (
            <div key={item} className="border-b-4 mx-4">
              <div className="flex justify-between px-4">
                <p>Order ID: {item}</p>
                <p>Order Date: {orderGroupByOrderIdx[item][0]?.reg_date}</p>
                <p>State: Completed</p>
              </div>
              {orderGroupByOrderIdx[item]?.map((product) => (
                <CartItem
                  type={type}
                  key={product?.product_idx}
                  item={product}
                />
              ))}
            </div>
          );
        })
      ) : (
        <>
          <div className="px-4 pt-4 flex text-xl border-b">
            <div className="block mr-2">
              <label className="inline-flex items-center ">
                <input
                  type="checkbox"
                  className="h-6 w-6 hover:bg-gray-200"
                  checked={
                    select.length > 0 && data?.length === select?.length
                      ? true
                      : false
                  }
                  onClick={selectAll}
                />
              </label>
            </div>
            <p>
              All Select ({select?.length} / {data?.length})
            </p>
          </div>
          {Array.isArray(data) ? (
            data?.map((item, idx) => (
              <CartItem
                type={type}
                key={item?.idx}
                item={item}
                handleRemoveCart={handleRemoveCart}
                selectItems={selectItems}
                select={select}
                quantityIncrement={quantityIncrement}
                quantityDecrement={quantityDecrement}
              />
            ))
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default CartItems;
