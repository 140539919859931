import React, { useState } from "react";
import Button from "../buttons/button";
import _sortBy from "lodash/sortBy";
import Select from "../inputs/select";
import { useGetHotelsCityQuery } from "../../API/tripApi";
import moment from "moment";

function Trip() {
  const [city, setCity] = useState("Seoul");

  const cities = useGetHotelsCityQuery();

  const sortedCities = _sortBy(cities.data, "list_order");

  const handleFind = () => {
    const href = `https://www.booking.com/searchresults.html?aid=7942599&ss=${city}&checkin_monthday=${moment().format(
      "DD"
    )}&checkin_year_month=${moment().format(
      "MM-yyyy"
    )}&checkout_monthday=${moment().format("MM")}&checkout_year_month=${moment()
      .add("days", 15)
      .format("MM-yyyy")}`;
    window.open(href, "_blank");
  };
  return (
    <div>
      <div className="mt-2">
        <Select
          label="City"
          isIcon
          data={sortedCities}
          handleChange={(e) => setCity(e.target.value)}
        />
      </div>
      <div className="mt-2">
        <Button text="Find your hotel" isIcon handleClick={handleFind} />
      </div>
    </div>
  );
}

export default Trip;
