import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
const google = new GoogleAuthProvider();

const firebaseConfig = {
  apiKey: "AIzaSyD-qOuYKaBIl6RSkLXjoCiBI8ciX004Z0Y",
  authDomain: "korea-travel-e215f.firebaseapp.com",
  projectId: "korea-travel-e215f",
  storageBucket: "korea-travel-e215f.appspot.com",
  messagingSenderId: "959900155010",
  appId: "1:959900155010:web:11eaacf5dd25f72e49c89b",
  measurementId: "G-T2QWMF5G81",
};

const firebase = initializeApp(firebaseConfig);

const auth = getAuth();

export { google, signInWithPopup, auth, firebase, GoogleAuthProvider };
