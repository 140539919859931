import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const CountryApi = createApi({
  reducerPath: "CountryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["country"],
  endpoints: (builder) => ({
    countryList: builder.query({
      query: () => ({ url: "/country-list" }),
      providesTags: ["country"],
    }),
    countryCities: builder.mutation({
      query: (country) => ({
        url: `/country-cities?country=${country}`,
        method: "GET",
      }),
      invalidatesTags: ["country"],
    }),
    countryCost: builder.mutation({
      query: (area_id) => ({
        url: `/country-cost?area_id=${area_id}`,
        method: "GET",
      }),
      invalidatesTags: ["country"],
    }),
  }),
});

export const {
  useCountryCitiesMutation,
  useCountryCostMutation,
  useCountryListQuery,
} = CountryApi;
