import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["cart"],
  endpoints: (builder) => ({
    addCart: builder.mutation({
      query: (body) => ({ url: "/cart", method: "POST", body }),
      invalidatesTags: ["cart"],
    }),
    removeCartItem: builder.mutation({
      query: (id) => ({ url: `/cart/${id}`, method: "DELETE" }),
      invalidatesTags: ["cart"],
    }),
    getCart: builder.query({
      query: (id) => ({ url: `/cart/${id}` }),
      providesTags: ["cart"],
    }),
  }),
});

export const {
  useAddCartMutation,
  useRemoveCartItemMutation,
  useGetCartQuery,
} = cartApi;
