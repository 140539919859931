import React from "react";
import { Config } from "../../config";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
function Photo({ photo, isAdmin, handleDeleteClick, handleClickImage }) {
  return (
    <div
      className="w-full overflow-hidden cursor-pointer"
      onClick={handleClickImage}
    >
      <img
        className="w-full h-56 object-cover"
        src={`${Config.server}${photo?.image}`}
        alt={`${Config.server}${photo?.image}`}
      />
      <p className="p-2 text-xl mb-4">{photo?.title}</p>
      {isAdmin && (
        <div className="flex">
          <button
            onClick={() => handleDeleteClick(photo?.idx)}
            className="block bg-red-400 text-white border p-1 hover:bg-red-500 active:shadow-inner focus:shadow-inner"
          >
            <RiDeleteBinLine size={18} />
          </button>
          <Link to={`/update/photo/${photo?.idx}`}>
            <span className="block bg-gray-50 border p-1 hover:bg-gray-100 active:shadow-inner focus:shadow-inner">
              <FiEdit size={18} />
            </span>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Photo;
