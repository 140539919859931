import React from "react";
import { useSelector } from "react-redux";
import Footer from "../footer";
import Navbar from "../navbar";
import SignIn from "../signIn";

const getNavbarState = (state) => state.navbar;

function Layout({ children }) {
  const navbar = useSelector(getNavbarState);

  return (
    <div>
      <Navbar />
      {children}
      <div className="mt-4">
        <Footer />
      </div>

      {navbar.open && (
        <div className="bg-[rgba(0,0,0,0.5)] z-50 h-[100vh] w-[100vw] fixed top-0 left-0">
          <SignIn />
        </div>
      )}
    </div>
  );
}

export default Layout;
