import React from "react";
import _className from "classnames";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";

function Pagination({ type, prevPage, nextPage, pages, page, handlePage }) {
  const handlePaginationClick = (page) => {
    handlePage(page);
  };

  let pageItems = [];
  for (let i = 0; i < pages; i++) {
    pageItems.push(i + 1);
  }

  const content = () => {
    if (type === "subway") {
      return (
        <button
          onClick={() => handlePaginationClick(page)}
          className="border rounded-lg px-4 py-1 flex items-center active:text-black"
        >
          <AiOutlineMenu className="mr-2 " />{" "}
          <span className="-mt-[6px]">List</span>
        </button>
      );
    }
    return (
      <div className="flex">
        {pageItems.map((item) => (
          <button
            key={item}
            onClick={() => handlePaginationClick(item)}
            className={_className(
              {
                "text-primary flex justify-center  items-center":
                  page === item ? true : false,
                "text-black": page === item ? false : true,
              },
              "cursor-pointer text-center  h-8 w-8 text-lg"
            )}
          >
            {item}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="flex justify-evenly text-gray-400 mt-10">
      <button
        onClick={prevPage}
        className="border rounded-lg pl-2 pr-4 flex justify-center items-center active:text-black"
      >
        <MdKeyboardArrowLeft size={20} />
        <span className="-mt-[6px]">Previous</span>
      </button>
      {content()}
      <button
        onClick={nextPage}
        className="border rounded-lg pl-4 pr-2 flex justify-center items-center active:text-black"
      >
        <span className="-mt-[6px]">Next</span>
        <MdKeyboardArrowRight size={20} />
      </button>
    </div>
  );
}

export default Pagination;
