import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Config.server}`,
  }),
  tagTypes: ["news"],
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => ({ url: "/news" }),
    }),
  }),
});

export const { useGetNewsQuery } = newsApi;
