import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../../components/inputs/textInput";
import Button from "../../../components/buttons/button";
import { AiOutlineFileImage } from "react-icons/ai";
import Loading from "../../../components/loading";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetVideoQuery,
  useGetVideosCategoryQuery,
} from "../../../API/videoApi";
import { Config } from "../../../config";

const initialValues = {
  title: "",
  category: "Campaign",
  url: "",
  image: "",
};

const schema = Yup.object().shape({
  title: Yup.string().required(),
  url: Yup.string().required(),
  category: Yup.string().required().default("Campaign"),
  image: Yup.string(),
});

function UpdateVideo() {
  let navigate = useNavigate();
  const { id } = useParams();
  const video = useGetVideoQuery(id);
  const videosCategory = useGetVideosCategoryQuery();
  let userAdmin;
  if (typeof window !== "undefined") {
    userAdmin = JSON.parse(sessionStorage.getItem("user"));
  }
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const handleUpdateVideo = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      if (values) {
        let FD = new FormData();
        FD.append("image", file ? file : values?.image);
        FD.append("title", values?.title);
        FD.append("url", values?.url);
        FD.append("category", values?.category);
        const response = await fetch(
          `${Config.server}/videos/${video.data?.data[0]?.idx}`,
          {
            method: "PUT",
            body: FD,
          }
        );
        setLoading(false);
        setMessage(response?.data?.message);
        window.location.href = "/media/video?page=1";
      }
    } catch (error) {
      setMessage(JSON.stringify(error));
    }
  };

  useEffect(() => {
    if (userAdmin?.id !== "admin") {
      navigate("/");
    }
  }, [userAdmin?.id]);

  if (video.data?.data?.length) {
    const v = video.data?.data[0];
    initialValues.category = v?.category;
    initialValues.title = v?.title;
    initialValues.url = v?.url;
    initialValues.image = v?.image;
  }
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        {loading && <Loading />}
        {message}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleUpdateVideo}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                placeholder="Title"
                type="text"
                onChange={handleChange}
                label="Title"
                name="title"
                value={values.title}
              />
              {errors.title && touched.title && errors.title}
              <TextInput
                placeholder="https://www.youtube.com/watch?v=idx"
                type="text"
                onChange={handleChange}
                name="url"
                label="Url"
                value={values.url}
                rows={6}
              />
              {errors.url && touched.url && errors.url}
              <select
                className="w-full border p-3 shadow-sm rounded-md"
                name="category"
                onChange={handleChange}
                value={values.category}
              >
                {videosCategory.data?.data?.map((ct) => (
                  <option value={ct?.category}>{ct?.category}</option>
                ))}
              </select>
              <div className="flex justify-center">
                {file ? (
                  <img src={image} alt={image} className="h-56 " />
                ) : (
                  <img
                    src={`${Config.static}${video?.data?.data[0]?.image}`}
                    alt={`${Config.static}${video?.data?.data[0]?.image}`}
                    className="h-56 "
                  />
                )}
              </div>
              <div className="border rounded my-2 shadow-sm p-2">
                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpg, image/jpeg"
                  hidden
                  name="image"
                  onChange={(e) => {
                    setImage(URL.createObjectURL(e.target.files[0]));
                    setFile(e.target.files[0]);
                  }}
                />
                <label
                  htmlFor="file"
                  className="flex justify-center items-center"
                >
                  <AiOutlineFileImage />
                  <span className="-mt-1 mx-4">Upload</span>
                </label>
              </div>
              {errors.image && touched.image && errors.image}
              <Button text="Update Video" />
            </form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}

export default UpdateVideo;
