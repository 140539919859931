import React, { useEffect, useState } from "react";
import BlogC from "../../components/blog/blogC";
import MyPlain from "../../components/forms/myPlain";
import Layout from "../../components/layout";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Pagination from "../../components/blog/pagination";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteBlogMutation,
  useGetBlogQuery,
  useGetBlogsQuery,
} from "../../API/blogaApi";
import Breadcrumb from "../../components/breadcrumb";
import Loading from "../../components/loading";
import { MdPostAdd } from "react-icons/md";
import News from "../../components/news/news";

function SingleBlog() {
  const { id } = useParams();

  const actualId = id?.split("id=")[1];
  const navigate = useNavigate();
  const [deleteBlogById] = useDeleteBlogMutation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const [user, setUser] = useState();
  const blogs = useGetBlogsQuery(1);
  const blog = useGetBlogQuery(actualId);

  const pages = Array?.isArray(blogs?.data?.data)
    ? Math.ceil(blogs?.data?.data?.length / 10)
    : 1;

  useEffect(() => {
    window.scrollTo(0, 0);
    setUser(JSON.parse(sessionStorage.getItem("user")));
  }, []);

  const nextPage = () => {
    if (page) {
      if (page == pages) {
        return navigate(`/blog/${id}?page=${Number(page)}`);
      }
      if (Array.isArray(blogs?.data?.data)) {
        return navigate(`/blog/${id}?page=${Number(page) + 1}`);
      }
    }
    return;
  };

  const prevPage = () => {
    if (page) {
      if (page < 2) {
        return navigate(`/blog/${id}?page=1`);
      }
      navigate(`/blog/${id}?page=${Number(page) - 1}`);
    }
    return;
  };
  const handlePage = (p) => {
    navigate(`/blog/${id}?page=${p}`);
  };
  const deleteBlog = async () => {
    try {
      const response = await deleteBlogById(blog?.data?.data[0]?.idx);
      if (response.data?.message?.includes("blog successfully")) {
        blogs.refetch();
        navigate("/blog?page=1");
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  if (blogs.isLoading) {
    return (
      <div className="flex justify-center items-center w-full h-[100vh]">
        <Loading />
      </div>
    );
  }
  return (
    <Layout>
      <div className="container mx-auto mt-32">
        <div className="flex justify-start flex-wrap w-full">
          <div className="w-full md:w-8/12 lg:w-9/12">
            <div className="flex">
              <Breadcrumb title={blog?.data?.data[0]?.title} />
              {user?.id === "admin" && (
                <div className="flex items-center mr-4">
                  <Link to={`/add/blog`}>
                    <span className="block bg-gray-50 border p-1 hover:bg-gray-100 active:shadow-inner focus:shadow-inner">
                      <MdPostAdd size={18} />
                    </span>
                  </Link>
                  <Link to={`/update/blog/${actualId}`}>
                    <span className="block bg-gray-50 border p-1 hover:bg-gray-100 active:shadow-inner focus:shadow-inner">
                      <FiEdit size={18} />
                    </span>
                  </Link>
                  <button
                    onClick={deleteBlog}
                    className="block bg-red-400 text-white border p-1 hover:bg-red-500 active:shadow-inner focus:shadow-inner"
                  >
                    <RiDeleteBinLine size={18} />
                  </button>
                </div>
              )}
            </div>
            <div className="p-2">
              {blog?.isLoading ? (
                <div className="flex justify-center items-center w-full h-[100vh]">
                  <Loading />
                </div>
              ) : (
                <BlogC item={blog?.data?.data[0]} />
              )}
              {/* <Zoom /> */}
              {blogs?.isLoading ? (
                <div className="flex justify-center items-center w-full h-[100vh]">
                  <Loading />
                </div>
              ) : (
                blogs?.data?.data?.map((item) => (
                  <News
                    key={item}
                    item={item}
                    type="blog"
                    scrollToTop={scrollToTop}
                  />
                ))
              )}

              {/* <div className="flex justify-end text-xl">
                <button className="bg-primary px-2 py-1 rounded-md text-white flex items-center">
                  <TiZoomInOutline className="mr-1" />{" "}
                  <span className=""> Zoom</span>
                </button>
              </div> */}
              <div className="my-8">
                <Pagination
                  type="subway"
                  pages={pages}
                  page={page}
                  prevPage={prevPage}
                  nextPage={nextPage}
                  handlePage={handlePage}
                />
              </div>
            </div>
          </div>

          <div className="w-full md:w-4/12 lg:w-3/12 mt-2">
            <MyPlain />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SingleBlog;
