import React from "react";

function PrimaryButton({
  text,
  className,
  onClick,
  isIcon = false,
  width = "w-96",
}) {
  return (
    <button
      onClick={onClick}
      className={`w-full md:${width} flex items-center text-white ${className}`}
    >
      {isIcon && (
        <img className="w-10 bg-white p-2" src="/image/gicon.png" alt="icon" />
      )}
      <span className="mx-auto -mt-1">{text}</span>
    </button>
  );
}

export default PrimaryButton;
