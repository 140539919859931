import React from "react";
import Layout from "../components/layout";
import TextInput from "../components/inputs/textInput";
import Button from "../components/buttons/button";
import ErrorMessage from "../components/inputs/errorMessage";
import * as Yup from "yup";
import { Formik } from "formik";
import { IoIosMailUnread } from "react-icons/io";
import { useUserForgetPasswordMutation } from "../API/signInApi";

const initialValues = {
  email: "",
};

const schema = Yup.object().shape({
  email: Yup.string().email().required("Email is required...!"),
});

function ForgetPassword() {
  const [forgetPassword, forgetPasswordResult] =
    useUserForgetPasswordMutation();

  const handleForgetPasword = async (values, { isSubmitting }) => {
    try {
      await forgetPassword(values);
    } catch (error) {
      console.log("ERROR IN FORGET PASSWORD", error);
    }
  };

  return (
    <Layout>
      {forgetPasswordResult?.data?.message?.includes("successfully") ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <div className="border p-5 px-20 rounded-md shadow-sm">
            <div className="flex justify-center items-center">
              <IoIosMailUnread size={80} color="#62c3f5" />
            </div>
            <h1 className="text-2xl font-bold">Please check email</h1>
            <p className="text-lg ">Password send on your email</p>
          </div>
        </div>
      ) : (
        <div className="container mx-auto mt-32 ">
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleForgetPasword}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  placeholder="Email"
                  type="email"
                  onChange={handleChange}
                  name="email"
                  label="Email"
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <ErrorMessage message={errors.email} />
                )}

                <Button
                  text={forgetPasswordResult.isLoading ? "Loading...!" : "Send"}
                  disabled={forgetPasswordResult.isLoading}
                />
              </form>
            )}
          </Formik>
        </div>
      )}
    </Layout>
  );
}

export default ForgetPassword;
